import React, { useState } from 'react';

const JsonFormat = () => {
  const [leftInput, setLeftInput] = useState('');
  const [rightOutput, setRightOutput] = useState('');

  const handleFormat = () => {
    try {
      const obj = JSON.parse(leftInput);
      const formatted = JSON.stringify(obj, null, 2);
      setRightOutput(formatted);
    } catch (error) {
      setRightOutput('无效的 JSON 格式');
    }
  };

  return (
    <div style={{ display: 'flex', padding: '20px', gap: '20px' }}>
      <div style={{ flex: 1 }}>
        <textarea
          value={leftInput}
          onChange={(e) => setLeftInput(e.target.value)}
          style={{
            width: '100%',
            height: '100',
            padding: '10px',
            fontFamily: 'monospace'
          }}
          placeholder="请输入 JSON 字符串"
        />
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <button 
          onClick={handleFormat}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer'
          }}
        >
          格式化 →
        </button>
      </div>

      <div style={{ flex: 1 }}>
        <textarea
          value={rightOutput}
          readOnly
          style={{
            width: '100%',
            height: '500px',
            padding: '10px',
            fontFamily: 'monospace',
            backgroundColor: '#f5f5f5'
          }}
          placeholder="格式化后的 JSON"
        />
      </div>
    </div>
  );
};

export default JsonFormat;
