import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TTSConfigLayout from './TTSConfigLayout';

const TTSConfigPage = () => {
  const [subscriptionKey, setSubscriptionKey] = useState('');
  const [region, setRegion] = useState('westus');
  const [alias, setAlias] = useState('');
  const [configName, setConfigName] = useState('');
  const [voiceOptions, setVoiceOptions] = useState([]);
  const [cnZhVoiceOptions, setCnZhVoiceOptions] = useState([]);
  const [enUsaVoiceOptions, setEnUsaVoiceOptions] = useState([]);
  const [enInVoiceOptions, setEnInVoiceOptions] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('');
  const [selectedCnZhVoice, setSelectedCnZhVoice] = useState('');
  const [selectedEnUsaVoice, setSelectedEnUsaVoice] = useState('');
  const [selectedEnInVoice, setSelectedEnInVoice] = useState('');
  const [configList, setConfigList] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [formatOptions, setFormatOptions] = useState(['Waiting for SDK loading.']);
  const [showConfigNameError, setShowConfigNameError] = useState(false);

  useEffect(() => {
    const savedConfig = JSON.parse(localStorage.getItem('ttsConfig'));
    if (savedConfig) {
      setSubscriptionKey(savedConfig.subscriptionKey);
      setRegion(savedConfig.region);
      setAlias(savedConfig.alias);
      setSelectedVoice(savedConfig.selectedVoice);
      setSelectedCnZhVoice(savedConfig.selectedCnZhVoice);
      setSelectedEnUsaVoice(savedConfig.selectedEnUsaVoice);
      setSelectedEnInVoice(savedConfig.selectedEnInVoice);
    }

    const savedSubscriptionKey = localStorage.getItem('subscriptionKey');
    if (savedSubscriptionKey) {
      setSubscriptionKey(savedSubscriptionKey);
    }

    refreshConfigList();
  }, []);

  const refreshConfigList = () => {
    const keys = Object.keys(localStorage).filter(
      (key) => key !== 'subscriptionKey'
    );
    const configs = keys.map((key) => ({
      key,
      value: JSON.parse(localStorage.getItem(key)),
    }));
    setConfigList(configs);
  };

  const saveConfig = () => {
    if (!configName) {
      setShowConfigNameError(true);
      return;
    }
    setShowConfigNameError(false);
    const config = {
      subscriptionKey,
      region,
      alias,
      selectedVoice,
      selectedCnZhVoice,
      selectedEnUsaVoice,
      selectedEnInVoice,
    };
    localStorage.setItem(configName, JSON.stringify(config));
    alert(
      'Configuration saved! configName:' +
        configName +
        ' ,value=' +
        JSON.stringify(config)
    );
    refreshConfigList();
  };

  const updateSubscriptionKey = () => {
    localStorage.setItem('subscriptionKey', subscriptionKey);
    alert('Subscription saved! subscriptionKey:' + subscriptionKey);
  };

  const updateVoiceList = async () => {
    if (!subscriptionKey || !region) {
      alert(
        'Please enter your Microsoft Cognitive Services Speech subscription key and region!'
      );
      return;
    }

    try {
      const headers = {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
      };

      const response = await axios.get(
        `https://${region}.tts.speech.microsoft.com/cognitiveservices/voices/list`,
        { headers }
      );

      const data = response.data;
      const voiceOptions = [];
      const cnZhVoiceOptions = [];
      const enUsaVoiceOptions = [];
      const enInVoiceOptions = [];

      data.forEach((voice) => {
        voiceOptions.push(voice.Name);
        if (voice.Locale.startsWith('zh-CN')) {
          cnZhVoiceOptions.push(voice.Name);
        }
        if (voice.Locale.startsWith('en-US')) {
          enUsaVoiceOptions.push(voice.Name);
        }
        if (voice.Locale.startsWith('en-IN')) {
          enInVoiceOptions.push(voice.Name);
        }
      });

      setVoiceOptions(voiceOptions);
      setCnZhVoiceOptions(cnZhVoiceOptions);
      setEnUsaVoiceOptions(enUsaVoiceOptions);
      setEnInVoiceOptions(enInVoiceOptions);

      setSelectedVoice(voiceOptions[0]);
      setSelectedCnZhVoice(cnZhVoiceOptions[0]);
      setSelectedEnUsaVoice(enUsaVoiceOptions[0]);
      setSelectedEnInVoice(enInVoiceOptions[0]);

      alert('Voice list updated successfully.');
    } catch (err) {
      console.error('Error updating voice list:', err);
      alert(
        'There was an error updating the voice list. Please check the console for more details.'
      );
    }
  };

  const handleConfigSelection = (config) => {
    setSubscriptionKey(config.value.subscriptionKey);
    setRegion(config.value.region);
    setAlias(config.value.alias);
    setSelectedVoice(config.value.selectedVoice);
    setSelectedCnZhVoice(config.value.selectedCnZhVoice);
    setSelectedEnUsaVoice(config.value.selectedEnUsaVoice);
    setSelectedEnInVoice(config.value.selectedEnInVoice);
    setSelectedConfig(config.key);
  };

  const deleteConfig = (key) => {
    localStorage.removeItem(key);
    refreshConfigList();
    alert(`Configuration ${key} deleted!`);
  };

  return (
    <TTSConfigLayout
      subscriptionKey={subscriptionKey}
      region={region}
      alias={alias}
      configName={configName}
      voiceOptions={voiceOptions}
      cnZhVoiceOptions={cnZhVoiceOptions}
      enUsaVoiceOptions={enUsaVoiceOptions}
      enInVoiceOptions={enInVoiceOptions}
      selectedVoice={selectedVoice}
      selectedCnZhVoice={selectedCnZhVoice}
      selectedEnUsaVoice={selectedEnUsaVoice}
      selectedEnInVoice={selectedEnInVoice}
      configList={configList}
      selectedConfig={selectedConfig}
      formatOptions={formatOptions}
      showConfigNameError={showConfigNameError}
      setSubscriptionKey={setSubscriptionKey}
      setRegion={setRegion}
      setAlias={setAlias}
      setConfigName={setConfigName}
      setSelectedVoice={setSelectedVoice}
      setSelectedCnZhVoice={setSelectedCnZhVoice}
      setSelectedEnUsaVoice={setSelectedEnUsaVoice}
      setSelectedEnInVoice={setSelectedEnInVoice}
      saveConfig={saveConfig}
      updateSubscriptionKey={updateSubscriptionKey}
      updateVoiceList={updateVoiceList}
      handleConfigSelection={handleConfigSelection}
      deleteConfig={deleteConfig}
      setFormatOptions={setFormatOptions}
      setShowConfigNameError={setShowConfigNameError}
      refreshConfigList={refreshConfigList}
    />
  );
};

export default TTSConfigPage;
