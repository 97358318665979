import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';

const EchartDependency = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [nameSet, setNameSet] = useState([]);
  const [checkedNames, setCheckedNames] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  // Initialize the chart and return the chart instance
  const initChart = () => {
    const chartDom = document.getElementById('main');
    return echarts.init(chartDom);
  };

  // Update the chart with the provided data
  const updateChart = (chartInstance, data) => {
    const option = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params) => {
          const { data } = params;
          return data.desc || data.name;
        },
      },
      series: [
        {
          type: 'tree',
          data: [data],
          left: '2%',
          right: '20%',
          top: '8%',
          bottom: '20%',
          symbol: 'emptyCircle',
          orient: 'horizontal',
          expandAndCollapse: true,
          nodeGap: 10,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
            fontSize: 9,
            formatter: (node) => node.data.desc || node.data.name,
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
            },
          },
          animationDurationUpdate: 750,
        },
      ],
    };
    chartInstance.setOption(option);
  };

  useEffect(() => {
    const myChart = initChart();
    myChart.showLoading();
    
    const basePath = `${window.location.origin}`;
    const flareJsonFilePath = `${basePath}/data/dependencies.json`;

    axios.get(flareJsonFilePath)
      .then((response) => {
        const data = response.data;
        setData(data);

        const names = new Set();
        const traverse = (node) => {
          names.add(node.name);
          if (node.children) {
            node.children.forEach(child => traverse(child));
          }
        };
        traverse(data);

        const sortedNames = Array.from(names).sort();
        setNameSet(sortedNames);
        setCheckedNames(sortedNames.reduce((acc, name) => {
          acc[name] = true;
          return acc;
        }, {}));

        myChart.hideLoading();
        setFilteredData(data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });

    return () => {
      myChart.dispose();
    };
  }, []);

  useEffect(() => {
    if (filteredData) {
      const myChart = initChart();
      updateChart(myChart, filteredData);
    }
  }, [filteredData]);

  const handleCheckboxChange = (name) => {
    setCheckedNames(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleFilterData = () => {
    const filterData = (node) => {
      if (!checkedNames[node.name]) return null;
      const filteredChildren = node.children
        ? node.children.map(filterData).filter(Boolean)
        : null;
      return {
        ...node,
        children: filteredChildren,
      };
    };
    setFilteredData(filterData(data));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectAll = () => {
    setCheckedNames(nameSet.reduce((acc, name) => {
      const node = findNodeByName(data, name);
      if (node && (name.toLowerCase().includes(searchTerm.toLowerCase()) || node.desc?.toLowerCase().includes(searchTerm.toLowerCase()))) {
        acc[name] = true;
      }
      return acc;
    }, { ...checkedNames }));
  };

  const handleDeselectAll = () => {
    setCheckedNames(nameSet.reduce((acc, name) => {
      const node = findNodeByName(data, name);
      if (node && (name.toLowerCase().includes(searchTerm.toLowerCase()) || node.desc?.toLowerCase().includes(searchTerm.toLowerCase()))) {
        acc[name] = false;
      }
      return acc;
    }, { ...checkedNames }));
  };

  const findNodeByName = (node, name) => {
    if (node.name === name) return node;
    if (node.children) {
      for (const child of node.children) {
        const result = findNodeByName(child, name);
        if (result) return result;
      }
    }
    return null;
  };

  const filteredNameSet = nameSet.filter(name => {
    const node = findNodeByName(data, name);
    return name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (node && node.desc?.toLowerCase().includes(searchTerm.toLowerCase()));
  });

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '20%', padding: '10px' }}>
        <button onClick={handleFilterData}>Apply Filter</button>

        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button onClick={handleSelectAll}>Select All</button>
        <button onClick={handleDeselectAll}>Deselect All</button>
        <ul>
          {filteredNameSet.map(name => {
            const node = findNodeByName(data, name);
            return (
              <li key={name}>
                <label>
                  <input
                    type="checkbox"
                    checked={checkedNames[name]}
                    onChange={() => handleCheckboxChange(name)}
                  />
                  {name} {node && node.desc ? `- ${node.desc}` : ''}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <div id="main" style={{ width: '80%', height: '600px' }}></div>
    </div>
  );
};

export default EchartDependency;
