import React from 'react';
import ReactJsonPretty from 'react-json-pretty';

const TTSConfigLayout = ({
  subscriptionKey,
  region,
  alias,
  configName,
  voiceOptions,
  cnZhVoiceOptions,
  enUsaVoiceOptions,
  enInVoiceOptions,
  selectedVoice,
  selectedCnZhVoice,
  selectedEnUsaVoice,
  selectedEnInVoice,
  configList,
  selectedConfig,
  formatOptions,
  showConfigNameError,
  setSubscriptionKey,
  setRegion,
  setAlias,
  setConfigName,
  setSelectedVoice,
  setSelectedCnZhVoice,
  setSelectedEnUsaVoice,
  setSelectedEnInVoice,
  saveConfig,
  updateSubscriptionKey,
  updateVoiceList,
  handleConfigSelection,
  deleteConfig,
  setFormatOptions,
  setShowConfigNameError,
  refreshConfigList
}) => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">TTS Configuration Page</h1>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <tbody>
            <tr>
              <td>
                <label
                  htmlFor="subscriptionKey"
                  className="block mb-2 font-semibold"
                >
                  <a
                    href="https://docs.microsoft.com/azure/cognitive-services/speech-service/get-started"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="link link-primary"
                  >
                    Subscription Key
                  </a>
                </label>
                <div className="form-control">
                  <input
                    id="subscriptionKey"
                    type="text"
                    placeholder="YourSubscriptionKey"
                    className="input input-bordered w-full max-w-xs"
                    value={subscriptionKey}
                    onChange={(e) => setSubscriptionKey(e.target.value)}
                  />
                  <button
                    id="updateSubscriptionKeyButton"
                    className="btn btn-primary mt-2"
                    onClick={updateSubscriptionKey}
                  >
                    Update Subscription Key
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="regionOptions"
                  className="block mb-2 font-semibold"
                >
                  Region
                </label>
                <select
                  id="regionOptions"
                  className="select select-bordered w-full"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option value="westus">West US</option>
                  <option value="westus2">West US2</option>
                  <option value="eastus">East US</option>
                  <option value="eastus2">East US2</option>
                  <option value="centralus">Central US</option>
                  <option value="northcentralus">North Central US</option>
                  <option value="southcentralus">South Central US</option>
                  <option value="westcentralus">West Central US</option>
                  <option value="canadacentral">Canada Central</option>
                  <option value="brazilsouth">Brazil South</option>
                  <option value="eastasia">East Asia</option>
                  <option value="southeastasia">South East Asia</option>
                  <option value="australiaeast">Australia East</option>
                  <option value="centralindia">Central India</option>
                  <option value="japaneast">Japan East</option>
                  <option value="japanwest">Japan West</option>
                  <option value="koreacentral">Korea Central</option>
                  <option value="northeurope">North Europe</option>
                  <option value="westeurope">West Europe</option>
                  <option value="francecentral">France Central</option>
                  <option value="switzerlandnorth">Switzerland North</option>
                  <option value="uksouth">UK South</option>
                  <option value="chinaeast2">China East2 (azure.cn)</option>
                  <option value="chinanorth2">China North2 (azure.cn)</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="voiceOptions"
                  className="block mb-2 font-semibold"
                >
                  Voice
                </label>
                <div className="form-control">
                  <button
                    id="updateVoiceListButton"
                    className="btn btn-primary mb-2"
                    onClick={updateVoiceList}
                  >
                    Update Voice List
                  </button>
                  <select
                    id="voiceOptions"
                    className="select select-bordered w-full"
                    value={selectedVoice}
                    onChange={(e) => setSelectedVoice(e.target.value)}
                    disabled={!voiceOptions.length}
                  >
                    {voiceOptions.length ? (
                      voiceOptions.map((voice, idx) => (
                        <option key={idx} value={voice}>
                          {voice}
                        </option>
                      ))
                    ) : (
                      <option>Please update voice list first.</option>
                    )}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="cnZhVoiceOptions"
                  className="block mb-2 font-semibold"
                >
                  CN Voice
                </label>
                <div className="form-control">
                  <button
                    id="updateCnZhVoiceListButton"
                    className="btn btn-primary mb-2"
                    onClick={updateVoiceList}
                  >
                    Update CN Voice List
                  </button>
                  <select
                    id="cnZhVoiceOptions"
                    className="select select-bordered w-full"
                    value={selectedCnZhVoice}
                    onChange={(e) => setSelectedCnZhVoice(e.target.value)}
                    disabled={!cnZhVoiceOptions.length}
                  >
                    {cnZhVoiceOptions.length ? (
                      cnZhVoiceOptions.map((voice, idx) => (
                        <option key={idx} value={voice}>
                          {voice}
                        </option>
                      ))
                    ) : (
                      <option>Please update CN voice list first.</option>
                    )}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="enUsaVoiceOptions"
                  className="block mb-2 font-semibold"
                >
                  English USA Voice
                </label>
                <div className="form-control">
                  <button
                    id="updateEnUsaVoiceListButton"
                    className="btn btn-primary mb-2"
                    onClick={updateVoiceList}
                  >
                    Update English USA Voice List
                  </button>
                  <select
                    id="enUsaVoiceOptions"
                    className="select select-bordered w-full"
                    value={selectedEnUsaVoice}
                    onChange={(e) => setSelectedEnUsaVoice(e.target.value)}
                    disabled={!enUsaVoiceOptions.length}
                  >
                    {enUsaVoiceOptions.length ? (
                      enUsaVoiceOptions.map((voice, idx) => (
                        <option key={idx} value={voice}>
                          {voice}
                        </option>
                      ))
                    ) : (
                      <option>
                        Please update English USA voice list first.
                      </option>
                    )}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="enInVoiceOptions"
                  className="block mb-2 font-semibold"
                >
                  English India Voice
                </label>
                <div className="form-control">
                  <button
                    id="updateEnInVoiceListButton"
                    className="btn btn-primary mb-2"
                    onClick={updateVoiceList}
                  >
                    Update English India Voice List
                  </button>
                  <select
                    id="enInVoiceOptions"
                    className="select select-bordered w-full"
                    value={selectedEnInVoice}
                    onChange={(e) => setSelectedEnInVoice(e.target.value)}
                    disabled={!enInVoiceOptions.length}
                  >
                    {enInVoiceOptions.length ? (
                      enInVoiceOptions.map((voice, idx) => (
                        <option key={idx} value={voice}>
                          {voice}
                        </option>
                      ))
                    ) : (
                      <option>
                        Please update English India voice list first.
                      </option>
                    )}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="formatOptions"
                  className="block mb-2 font-semibold"
                >
                  Format
                </label>
                <select
                  id="formatOptions"
                  className="select select-bordered w-full"
                  value={formatOptions[0]}
                  onChange={(e) => setFormatOptions([e.target.value])}
                >
                  {formatOptions.map((format, idx) => (
                    <option key={idx} value={format}>
                      {format}
                    </option>
                  ))}
                </select>
                <span className="block mt-2 text-sm text-gray-600">
                  (riff pcm, mp3, ogg and webm formats are supported for
                  playback.)
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor="configName"
                  className="block mb-2 font-semibold"
                >
                  Configuration Name
                </label>
                <div className="relative">
                  <input
                    id="configName"
                    type="text"
                    className={`input input-bordered w-full max-w-xs ${
                      showConfigNameError ? 'input-error' : ''
                    }`}
                    value={configName}
                    onChange={(e) => {
                      setConfigName(e.target.value);
                      setShowConfigNameError(false);
                    }}
                  />
                  {showConfigNameError && (
                    <span className="text-error text-sm absolute top-full left-0 mt-1">
                      Configuration name is required.
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button className="btn btn-primary mt-4" onClick={saveConfig}>
                  Save Configuration
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Saved Configurations</h2>
        <button className="btn btn-secondary mb-4" onClick={refreshConfigList}>
          Refresh Config List
        </button>
        <table className="table w-full">
          <thead>
            <tr>
              <th>Config Name</th>
              <th>Config Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {configList.map((config, idx) => (
              <tr
                key={idx}
                className={selectedConfig === config.key ? 'bg-gray-200' : ''}
                onClick={() => handleConfigSelection(config)}
              >
                <td>{config.key}</td>
                <td>
                  <ReactJsonPretty data={config.value} />
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteConfig(config.key)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TTSConfigLayout;
