// TTSPage.js
import React, { useState, useEffect } from 'react';
import { downloadSynthesis } from './TTSPageFunDownload';
import TTSPageLayoutHtml from './TTSPageLayoutHtml';
import { startSynthesis } from './TTSPageFunSynthesis';

const TTSPage = () => {
  const [configs, setConfigs] = useState([]);
  const [text, setText] = useState('');
  const [speechSDK, setSpeechSDK] = useState(null);
  const [selectedConfigKey, setSelectedConfigKey] = useState('');
  const [selectedConfigValue, setSelectedConfigValue] = useState('');
  const [selectedConfigDetails, setSelectedConfigDetails] = useState({
    subscriptionKey: '',
    region: '',
    alias: '',
  });
  const [speed, setSpeed] = useState(1);
  const [isSsml, setIsSsml] = useState(false);
  const [isPausedEnable, setPausedEnable] = useState(false);
  const [
    isStartSynthesisAsyncButtonEnable,
    setStartSynthesisAsyncButtonEnable,
  ] = useState(false);
  const [isDownloadButtonEnable, setDownloadButtonEnable] = useState(false);
  const [isResumeButtonEnable, setResumeButtonEnable] = useState(false);
  const [audioPlayerUrl, setAudioPlayerUrl] = useState('');

  const [results, setResults] = useState('');
  const [events, setEvents] = useState('');
  const [format, setFormat] = useState('Audio24Khz160KBitRateMonoMp3');
  const [player, setPlayer] = useState(null);
  const [highlightText, setHighlightText] = useState(''); // New state for highlighted text

  useEffect(() => {
    console.log(`TTSPage useEffect : ${events}`);
  }, [events]);

  useEffect(() => {
    const keys = Object.keys(localStorage);
    console.log('localStorage keys:');
    console.log(keys);
    const ttsConfigs = keys
      .filter((key) => key !== 'ttsConfig')
      .map((key) => {
        try {
          const config = JSON.parse(localStorage.getItem(key));
          if (config) {
            return { name: key, config };
          } else {
            return {
              name: key,
              config: { subscriptionKey: '', region: '', alias: key },
            };
          }
        } catch (e) {
          return {
            name: key,
            config: { subscriptionKey: '', region: '', alias: key },
          };
        }
      });
    setConfigs(ttsConfigs);
    console.log('setConfigs :');
    console.log(ttsConfigs);

    const loadSpeechSDK = () => {
      if (window.SpeechSDK) {
        setSpeechSDK(window.SpeechSDK);
      } else {
        console.error('SpeechSDK not found.');
      }
    };

    loadSpeechSDK();

    const params = new URLSearchParams(window.location.search);
    const configKey = params.get('config');
    if (configKey) {
      if (localStorage.getItem(configKey)) {
        setSelectedConfigKey(configKey);
        const config = JSON.parse(localStorage.getItem(configKey));
        setSelectedConfigValue(config);
        setSelectedConfigDetails(config);
      } else {
        alert(`No config found for key: ${configKey}`);
      }
    }
  }, []);

  const handleConfigChange = (e) => {
    const selected = e.target.value;
    setSelectedConfigKey(selected);
    if (selected) {
      const config = JSON.parse(localStorage.getItem(selected));
      setSelectedConfigValue(config);
      setSelectedConfigDetails(config);
    } else {
      setSelectedConfigDetails({ subscriptionKey: '', region: '', alias: '' });
    }
  };

  const handlePause = () => {
    if (player) {
      player.pause();
    }
  };

  const handleResume = () => {
    if (player) {
      player.play();
    }
  };

  const setVarEvents = (t) => {
    console.log(`setVarEvents called with ${t}`);
  };

  const handleDownloadSynthesis = async () => {
    await downloadSynthesis(
      speechSDK,
      selectedConfigKey,
      format,
      speed,
      text,
      isSsml,
      setEvents,
      setResults,
      selectedConfigValue['selectedEnUsaVoice'],
      setAudioPlayerUrl
    );
  };

  return (
    <TTSPageLayoutHtml
      text={text}
      setText={setText}
      speed={speed}
      setSpeed={setSpeed}
      isSsml={isSsml}
      setIsSsml={setIsSsml}
      format={format}
      setFormat={setFormat}
      startSynthesis={() =>
        startSynthesis(
          speechSDK,
          selectedConfigKey,
          text,
          isSsml,
          setEvents,
          setStartSynthesisAsyncButtonEnable,
          setDownloadButtonEnable,
          setPausedEnable,
          setResumeButtonEnable,
          setVarEvents,
          selectedConfigValue['selectedEnUsaVoice'],
          setHighlightText
        )
      }
      isStartSynthesisAsyncButtonEnable={isStartSynthesisAsyncButtonEnable}
      handlePause={handlePause}
      isPausedEnable={isPausedEnable}
      handleResume={handleResume}
      isResumeButtonEnable={isResumeButtonEnable}
      downloadSynthesis={handleDownloadSynthesis}
      isDownloadButtonEnable={isDownloadButtonEnable}
      configs={configs}
      selectedConfig={selectedConfigKey}
      handleConfigChange={handleConfigChange}
      currentConfigDetails={selectedConfigDetails}
      results={results}
      events={events}
      highlightText={highlightText}
      audioPlayerUrl={audioPlayerUrl}
    />
  );
};

export default TTSPage;
