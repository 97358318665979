import React, { useEffect, useState } from 'react';

const YouglishWidget = () => {
  const [width, setWidth] = useState(900);
  const [height, setHeight] = useState(480);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://youglish.com/public/emb/widget.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div>
        <label>宽度: {width}px</label>
        <input
          type="range"
          min="200"
          max="1200"
          value={width}
          onChange={(e) => setWidth(Number(e.target.value))}
        />
      </div>
      <div>
        <label>高度: {height}px</label>
        <input
          type="range"
          min="200"
          max="800"
          value={height}
          onChange={(e) => setHeight(Number(e.target.value))}
        />
      </div>
      <div style={{ width: `${width}px`, height: `${height}px` }}>
        <div>
          <a
            id="yg-widget-0"
            className="youglish-widget"
            data-query="great%20power"
            data-lang="english"
            data-components="8415"
            data-bkg-color="theme_light"
            rel="nofollow"
            href="https://youglish.com"
          >
            Visit YouGlish.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default YouglishWidget;
