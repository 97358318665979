import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

// Helper functions
const random = (min, max) => Math.random() * (max - min) + min;
const randomColor = () =>
  `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;

// Function to initialize WaveSurfer with Regions plugin
const initWaveSurfer = (
  container,
  blobUrl,
  height,
  setLoadingCount,
  setLoadState,
  updateCurrentTimeAndDuration, // Pass the updateCurrentTimeAndDuration function as a parameter
  isUsePeaks,
  peaksData
) => {
  try {
    console.log('initWaveSurfer peaks split');
    let peaksArr;
    if (isUsePeaks) {
      peaksArr = peaksData.split(',').map(Number);
      peaksArr = [peaksArr];
    } else {
      peaksArr = null;
    }
    console.log(peaksArr);
    const ws = WaveSurfer.create({
      container,
      mediaType: 'audio/mpeg',
      waveColor: 'rgb(200, 0, 200)',
      progressColor: 'rgb(100, 0, 100)',
      url: blobUrl,
      // backend: 'MediaElement',
      backend: 'WebAudio',
      height, // 波形高度
      // peaks: [
      //   [
      //     0, 0.0023595101665705442, 0.012107174843549728, 0.005919494666159153, -0.31324470043182373, 0.1511787623167038,
      //   ],
      // ],
      peaks: peaksArr,
    });

    const wsRegions = ws.registerPlugin(RegionsPlugin.create());

    ws.on('decode', () => {
      const regions = [];
      regions.forEach((region) => {
        console.log('oncallback decode');
        wsRegions.addRegion({
          ...region,
          color: randomColor(),
        });
      });
      setLoadState((prevLoadState) => 'decode');
    });

    ws.on('loading', () => {
      setLoadingCount((prevCount) => prevCount + 1);
      setLoadState((prevLoadState) => 'loading');
      console.log('oncallback loading');
    });

    ws.on('ready', () => {
      setLoadState((prevLoadState) => 'ready');
      console.log('ws.on ready');
    });

    ws.on('audioprocess', () => {
      console.log('ws.on audioprocess');
      updateCurrentTimeAndDuration(); // Call the function here
    });

    ws.on('error', (e) => {
      console.log('ws.on error');
      console.log(e);
    });

    wsRegions.enableDragSelection({
      color: 'rgba(255, 0, 0, 0.1)',
    });

    wsRegions.on('region-updated', (region) => {
      console.log('Updated region', region);
    });

    return { ws, wsRegions };
  } catch (error) {
    console.log('initWaveSurfer error ');
    console.log(error);
  }
};

// Function to create blob URL for a specific time segment of an audio file
const createBlobUrl = (audioFile, startTime, endTime, setBlobUrl) => {
  console.log('createBlobUrl {:');
  console.log('audioFile type:');
  console.log(audioFile);

  const audioDuration = audioFile.duration; // Assuming audioFile has a duration property
  const fileSize = audioFile.size; // Assuming audioFile has a size property

  console.log(`audioDuration: ${audioDuration}, fileSize: ${fileSize}`);

  console.log('createBlobUrl audioFile:');
  console.log(audioFile);
  console.log('createBlobUrl startTime:');
  console.log(startTime);
  const chunkSize = 500 * 1024; // 1MB chunks
  const start = startTime * chunkSize;
  const end = start + chunkSize;

  console.log(`chunkSize: ${chunkSize} , start: ${start}, end: ${end}`);
  const blob = audioFile.slice(start, end);
  URL.revokeObjectURL(audioFile);

  console.log(`createBlobUrl after slice blob: `);
  console.log(blob);
  // Getting the size of the blob
  const blobSize = blob.size;
  console.log('Blob size:');
  console.log(blobSize);

  const url = URL.createObjectURL(blob);
  console.log('createBlobUrl result:');
  console.log(url);

  setBlobUrl(url);
};

// WaveSurferComponent
const AudioRegionPlayer = ({ audioUrl }) => {
  const waveformRef1 = useRef(null);
  const waveformRef2 = useRef(null);
  const playButtonRef = useRef(null);
  const pauseButtonRef = useRef(null);
  const zoomSliderRef = useRef(null);
  const [height, setHeight] = useState(100);
  const [loadingCount, setLoadingCount] = useState(0);
  const [loadState, setLoadState] = useState(0);
  const [audio1CurrentTime, setAudio1CurrentTime] = useState(0);
  const [audio1Duration, setAudio1Duration] = useState(0);
  const [audio2Duration, setAudio2Duration] = useState(0);
  const [audio2CurrentTime, setAudio2CurrentTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(1000);
  const [blobUrl1, setBlobUrl1] = useState('');
  const [blobUrl2, setBlobUrl2] = useState('');
  const [chunkBlobUrl, setChunkBlobUrl] = useState('blob url');
  const [peaksData, setPeaksData] = useState('peaks');
  const [isUsePeaks, setIsUsePeaks] = useState('isUsePeaks');

  useEffect(() => {
    if (!blobUrl1 || !blobUrl2) return;

    console.log('initWaveSurfer start:');

    // Function to update current time and duration
    const updateCurrentTimeAndDuration = () => {
      console.log('updateCurrentTimeAndDuration:');
      setAudio1CurrentTime(ws1.getCurrentTime());
      setAudio1Duration(ws1.getDuration());

      setAudio2CurrentTime(ws2.getCurrentTime());
      setAudio2Duration(ws2.getDuration());
    };

    console.log('blobUrl1 :');
    console.log(blobUrl1);

    console.log('blobUrl2 :');
    console.log(blobUrl2);

    const isUsePeaks = document.getElementById('checkboxPeaks').checked; // 获取 checkbox 状态
    const { ws: ws1, wsRegions: wsRegions1 } = initWaveSurfer(
      waveformRef1.current,
      blobUrl1,
      height,
      setLoadingCount,
      setLoadState,
      updateCurrentTimeAndDuration, // Pass the function here
      isUsePeaks,
      peaksData
    );
    const { ws: ws2, wsRegions: wsRegions2 } = initWaveSurfer(
      waveformRef2.current,
      blobUrl2,
      height,
      setLoadingCount,
      setLoadState,
      updateCurrentTimeAndDuration, // Pass the function here
      isUsePeaks,
      peaksData
    );
    console.log('initWaveSurfer end:');
    console.log('ws1:');
    console.log(ws1);
    console.log('ws2:');
    console.log(ws2);

    let loop = true;

    // Function to play selected regions
    const playSelectedRegions = () => {
      const activeRegions = [wsRegions1, wsRegions2].map((wsRegions) => {
        const regions = wsRegions.getRegions();
        return Object.values(regions).find((region) =>
          region.element.classList.contains('active')
        );
      });

      activeRegions.forEach((region) => {
        if (region) {
          region.play();
        }
      });

      ws1.on('audioprocess', updateCurrentTimeAndDuration);
      ws2.on('audioprocess', updateCurrentTimeAndDuration);
    };

    // Function to pause playback
    const pausePlayback = () => {
      document.activeElement.blur(); // 释放当前元素的焦点
      ws1.pause();
      ws2.pause();
      ws1.un('audioprocess', updateCurrentTimeAndDuration);
      ws2.un('audioprocess', updateCurrentTimeAndDuration);
    };

    // Event listeners for control buttons
    playButtonRef.current.onclick = playSelectedRegions;
    pauseButtonRef.current.onclick = pausePlayback;

    // Setup region events for both instances
    const setupRegionEvents = (ws, wsRegions) => {
      let activeRegion = null;

      wsRegions.on('region-in', (region) => {
        console.log('region-in', region);
        updateCurrentTimeAndDuration();
        activeRegion = region;
      });

      wsRegions.on('region-out', (region) => {
        console.log('region-out', region);
        if (activeRegion === region) {
          if (loop) {
            region.play();
          } else {
            activeRegion = null;
          }
        }
      });

      wsRegions.on('region-clicked', (region, e) => {
        e.stopPropagation();

        // Remove active class from all regions
        Object.values(wsRegions.getRegions()).forEach((r) =>
          r.element.classList.remove('active')
        );

        activeRegion = region;

        // Add active class to the selected region
        document
          .querySelectorAll('.wavesurfer-region')
          .forEach((el) => el.classList.remove('active'));
        region.element.classList.add('active');

        // Play the selected region
        region.play();
        region.setOptions({ color: randomColor() });
      });

      ws.on('interaction', () => {
        activeRegion = null;
      });
    };

    setupRegionEvents(ws1, wsRegions1);
    setupRegionEvents(ws2, wsRegions2);

    // Update zoom level on slider change
    zoomSliderRef.current.oninput = (e) => {
      const minPxPerSec = Number(e.target.value);
      ws1.zoom(minPxPerSec);
      ws2.zoom(minPxPerSec);
    };

    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.onclick = (e) => {
        loop = e.target.checked;
      };
    });

    console.log('return destroy');
    return () => {
      ws1.destroy();
      ws2.destroy();
    };
  }, [blobUrl1, blobUrl2, height, peaksData, isUsePeaks]);

  const handleSetSegments = () => {
    console.log('handleSetSegments {');
    if (chunkBlobUrl !== 'blob url') {
      console.log('setBlobUrl1');
      setBlobUrl1(chunkBlobUrl);
      setBlobUrl2(chunkBlobUrl);
    } else {
      const audioFile = document.querySelector('input[type="file"]').files[0];
      console.log('handleSetSegments:');
      console.log(audioFile);
      console.log('setBlobUrl1');
      createBlobUrl(audioFile, startTime, endTime, setBlobUrl1);
      createBlobUrl(audioFile, startTime, endTime, setBlobUrl2);
    }
    console.log('handleSetSegments }');
  };

  // 添加处理 checkboxPeaks 变化的函数
  const handleCheckboxChange = () => {
    setIsUsePeaks(document.getElementById('checkboxPeaks').checked);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl mb-4">
        <div className="card-body">
          <input type="file" className="file-input file-input-bordered w-full max-w-xs" />
          <div className="form-control">
            <label className="label">
              <span className="label-text">开始时间:</span>
              <input
                type="number"
                value={startTime}
                onChange={(e) => setStartTime(Number(e.target.value))}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">结束时间:</span>
              <input
                type="number"
                value={endTime}
                onChange={(e) => setEndTime(Number(e.target.value))}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Blob URL:</span>
              <input
                value={chunkBlobUrl}
                onChange={(e) => setChunkBlobUrl(e.target.value)}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">峰值数据:</span>
              <input
                value={peaksData}
                onChange={(e) => setPeaksData(e.target.value)}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label className="label cursor-pointer">
              <span className="label-text">使用峰值覆盖</span>
              <input
                type="checkbox"
                id="checkboxPeaks"
                onChange={handleCheckboxChange}
                className="checkbox"
              />
            </label>
          </div>
          <button onClick={handleSetSegments} className="btn btn-primary">设置片段</button>
        </div>
      </div>
      
      <div ref={waveformRef1} id="waveform" className="mb-4"></div>
      <div ref={waveformRef2} id="waveform2" className="mb-4"></div>
      
      <div className="flex flex-wrap gap-4 mb-4">
        <button ref={playButtonRef} className="btn btn-success">播放选中区域</button>
        <button ref={pauseButtonRef} className="btn btn-warning">暂停播放</button>
      </div>
      
      <div className="form-control mb-4">
        <label className="label cursor-pointer">
          <span className="label-text">循环区域 1</span>
          <input type="checkbox" defaultChecked className="checkbox" />
        </label>
        <label className="label cursor-pointer">
          <span className="label-text">循环区域 2</span>
          <input type="checkbox" defaultChecked className="checkbox" />
        </label>
      </div>
      
      <div className="form-control mb-4">
        <label className="label">
          <span className="label-text">缩放:</span>
          <input
            ref={zoomSliderRef}
            type="range"
            min="1"
            max="1000"
            defaultValue="1"
            className="range range-primary"
          />
        </label>
      </div>
      
      <div className="form-control mb-4">
        <label className="label">
          <span className="label-text">高度:</span>
          <input
            type="range"
            min="100"
            max="1000"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
            className="range range-secondary"
          />
        </label>
      </div>

      <div className="stats shadow">
        <div className="stat">
          <div className="stat-title">加载状态</div>
          <div className="stat-value">{loadState}</div>
        </div>
        <div className="stat">
          <div className="stat-title">加载计数</div>
          <div className="stat-value">{loadingCount}</div>
        </div>
      </div>

      <div className="stats shadow mt-4">
        <div className="stat">
          <div className="stat-title">音频 1 当前时间 / 总时长</div>
          <div className="stat-value">{audio1CurrentTime.toFixed(2)} / {audio1Duration.toFixed(2)}</div>
        </div>
        <div className="stat">
          <div className="stat-title">音频 2 当前时间 / 总时长</div>
          <div className="stat-value">{audio2CurrentTime.toFixed(2)} / {audio2Duration.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default AudioRegionPlayer;
