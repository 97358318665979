import React from 'react';

const GoogDriveManagerLayout = ({
  handleAuthClick,
  handleSignoutClick,
  listFolders,
  handleUploadClick,
  folderName,
  setFolderName,
  handleCreateFolderClick,
  selectedFolderId,
  setSelectedFolderId,
  folders,
  handleFileClick,
  confirmDelete,
  fileToDelete,
  handleDeleteClick,
  closeModal,
  selectedFile,
  repeatCount,
  setRepeatCount,
  fileLoadProgressState,
}) => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Drive API Quickstart with AppData</h1>
      
      {/* 操作按钮区 */}
      <div className="mb-8 p-4 bg-base-200 rounded-box">
        <h2 className="text-xl font-semibold mb-4">操作区</h2>
        <div className="flex flex-wrap gap-2 mb-4">
          <button id="authorize_button" onClick={handleAuthClick} className="btn btn-primary btn-sm">
            授权
          </button>
          <button id="signout_button" onClick={handleSignoutClick} className="btn btn-secondary btn-sm">
            退出登录
          </button>
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          <div className="flex flex-col gap-2">
            <button id="list_folders_button" onClick={listFolders} className="btn btn-info btn-sm">
              列出AppData文件夹
            </button>
            <progress 
              className="progress progress-info w-56" 
              value={fileLoadProgressState.value} 
              max={fileLoadProgressState.max}
            ></progress>
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <input type="text" id="file_name" className="input input-bordered input-sm w-full max-w-xs" readOnly placeholder="未选择文件" />
          <label htmlFor="file_input" className="btn btn-sm btn-primary ml-2">
            选择文件
          </label>
          <input type="file" id="file_input" className="hidden" multiple onChange={(e) => {
            const fileNames = Array.from(e.target.files).map(file => file.name).join(', ');
            document.getElementById('file_name').value = fileNames || '未选择文件';
          }} />
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          
          <input
            type="text"
            id="folder_name_input"
            className="input input-bordered input-sm max-w-xs"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="文件夹名称"
          />
          <button id="create_folder_button" onClick={handleCreateFolderClick} className="btn btn-warning btn-sm">
            在AppData中创建文件夹
          </button>
        </div>
        <div className="mb-4">
          <select
            id="folder_select"
            className="select select-bordered select-sm w-full max-w-xs"
            onChange={(e) => setSelectedFolderId(e.target.value)}
            value={selectedFolderId}
          >
            <option value="">选择文件夹</option>
            {folders.map((folder) => (
              <option key={folder.id} value={folder.id}>
                {folder.name}
              </option>
            ))}
          </select>
          <button id="upload_button" onClick={handleUploadClick} className="btn btn-success btn-sm">
            上传文件到AppData
          </button>
        </div>
      </div>

      {/* 展示结果区 */}
      <div className="p-4 bg-base-200 rounded-box">
        <h2 className="text-xl font-semibold mb-4">结果展示区</h2>
        <pre id="content" className="whitespace-pre-wrap mb-4"></pre>

        <ul id="folder_list" className="menu menu-compact bg-base-300 w-full rounded-box mb-4">
          {folders.map((folder) => (
            <li key={folder.id} className="w-full">
              <div className="flex justify-between items-center p-2 bg-base-300 rounded w-full">
                <span className="cursor-pointer" onClick={() => handleFileClick(folder.id, folder.mimeType)}>
                  {folder.name} ({folder.id})
                </span>
                <button onClick={() => confirmDelete(folder.id)} className="btn btn-error btn-xs">
                  删除
                </button>
              </div>
            </li>
          ))}
        </ul>

        {selectedFile && (
          <div className="mb-4">
            {selectedFile.mimeType.startsWith('image/') && (
              <img src={selectedFile.url} alt="Selected" className="max-w-xs" />
            )}
            {selectedFile.mimeType === 'audio/mpeg' && (
              <div>
                <audio controls src={selectedFile.url} loop={repeatCount > 1}></audio>
                <div className="mt-2">
                  <label htmlFor="repeat_count" className="mr-2">重复次数: </label>
                  <select
                    id="repeat_count"
                    value={repeatCount}
                    onChange={(e) => setRepeatCount(Number(e.target.value))}
                    className="select select-bordered select-sm"
                  >
                    {[...Array(10).keys()].map((n) => (
                      <option key={n + 1} value={n + 1}>
                        {n + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* 确认删除模态框 */}
      <input type="checkbox" id="confirmation_modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3>确定要删除这个文件吗？</h3>
          <div className="modal-action">
            <button onClick={() => handleDeleteClick(fileToDelete)} className="btn btn-error btn-sm">
              是
            </button>
            <button onClick={closeModal} className="btn btn-secondary btn-sm">
              否
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// 添加 getUIElements 函数
export const getGoogDriveManagerLayout = () => {
  return {
    authorizeButton: document.getElementById('authorize_button'),
    signoutButton: document.getElementById('signout_button'),
    listFoldersButton: document.getElementById('list_folders_button'),
    uploadButton: document.getElementById('upload_button'),
    createFolderButton: document.getElementById('create_folder_button'),
    content: document.getElementById('content'),
    fileInput: document.getElementById('file_input'),
    confirmationModal: document.getElementById('confirmation_modal'),
  };
};

export default GoogDriveManagerLayout;
