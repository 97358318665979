import { useEffect } from 'react';

const AzureTTS = () => {
  useEffect(() => {
    // 重定向到 a.html
    window.location.href = '/html-AzureTTS-synthesis.html';
  }, []);

  return null; // 由于页面会重定向，这里不需要返回任何内容
};

export default AzureTTS;
