import * as Papa from 'papaparse';

class CsvProcessor {
  constructor(bbcNewsText, googleWordCsv) {
    this.bbcNewsText = bbcNewsText;
    this.googleWordCsv = googleWordCsv;
  }

  processCsvData() {
    // Split the BBC News text by commas and trim any extra spaces
    const bbcNewsData = this.bbcNewsText.split(',').map((word) => word.trim());

    console.log("Parsed BBC News Data:", bbcNewsData); // Log BBC News data from text

    // Log each word as it's being processed
    const bbcWords = bbcNewsData.map((word, index) => {
        console.log(`Processing word ${index}:`, word); // Log the current word
        return word ? word.toUpperCase() : '';  // Handle potential undefined or empty values
    });

    console.log("BBC Words (uppercased):", bbcWords); // Log the transformed BBC words

    // Parse the Google Word CSV using PapaParse
    const googleWordData = Papa.parse(this.googleWordCsv, { header: true });
    const googleWords = googleWordData.data.map((row) => row.Word.toUpperCase());
    console.log("Google Words (uppercased):", googleWords); // Log Google words

    // Filter out BBC words that are not in Google words
    const result = bbcWords.filter((word) => word && !googleWords.includes(word));

    console.log("Result (words not found in Google):", result); // Log the final result
    return result;
  }

}

export default CsvProcessor;
