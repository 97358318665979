import React, { useEffect, useState, useRef } from 'react';
import 'daisyui/dist/full.css';
import GoogDriveManagerLayout, { getGoogDriveManagerLayout } from './GoogDriveManagerLayout';

const GoogDriveListSave = () => {
  const [folders, setFolders] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [repeatCount, setRepeatCount] = useState(1);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileLoadProgressState, setFileLoadProgressState] = useState({ value: 0, max: 0 });

  // Google API 客户端 ID
  const CLIENT_ID =
    '1085518854241-r4uvt7rpu6uh9hv1ciedgv6g6tl3ro33.apps.googleusercontent.com';
  // Google API 密钥
  const API_KEY = 'AIzaSyDyev_rOmTm5N9Df2wYegy3fuQXnQJ5rz8';

  // Google Drive API 的发现文档 URL
  const DISCOVERY_DOC =
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
  // 请求的 API 范围，这里是 appdata 文件夹的访问权限
  const SCOPES = 'https://www.googleapis.com/auth/drive.appdata';

  // 用于存储 token 客户端的引用
  const tokenClient = useRef(null);
  // 标记 GAPI 是否已初始化
  // GAPI: Google API JavaScript 客户端库，用于与各种 Google API 进行交互
  const gapiInited = useRef(false);
  // 标记 GIS（Google Identity Services）是否已初始化
  // GIS: Google 身份服务，用于处理用户身份验证和授权
  const gisInited = useRef(false);
  // 存储 UI 元素的引用
  const uiElements = useRef(null);

  // 初始化 GAPI 客户端
  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited.current = true;
    maybeEnableButtons();
    
    // 检查本地存储中是否有缓存的 token
    const cachedToken = localStorage.getItem('google_token');
    if (cachedToken) {
      // 如果有缓存的 token，设置它并显示相关按钮
      window.gapi.client.setToken(JSON.parse(cachedToken));
      showButtons();
    }
  };

  // GIS 加载完成后的回调
  const initializeGisClient = () => {
    tokenClient.current = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // 稍后定义
      expires_in: 604800 // token 有效期为 7 天
    });
    gisInited.current = true;
    maybeEnableButtons();
  };

  // 如果 GAPI 和 GIS 都已初始化，则启用授权按钮
  const maybeEnableButtons = () => {
    if (gapiInited.current && gisInited.current) {
      uiElements.current.authorizeButton.style.visibility = 'visible';
    }
  };

  // 显示相关按钮
  const showButtons = () => {
    uiElements.current.signoutButton.style.visibility = 'visible';
    uiElements.current.listFoldersButton.style.visibility = 'visible';
    uiElements.current.uploadButton.style.visibility = 'visible';
    uiElements.current.createFolderButton.style.visibility = 'visible';
    uiElements.current.authorizeButton.innerText = 'Refresh';
  };

  // 动态加载脚本
  const loadScript = (src, onload) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = onload;
    document.body.appendChild(script);
  };

  useEffect(() => {
    // 获取 UI 元素
    uiElements.current = getGoogDriveManagerLayout();

    // GAPI 加载完成后的回调
    const gapiLoaded = () => {
      window.gapi.load('client', initializeGapiClient);
    };

    // 设置全局回调函数
    window.gapiLoaded = gapiLoaded;
    window.gisLoaded = initializeGisClient;

    // 动态加载 GAPI 和 GIS 脚本
    loadScript('https://apis.google.com/js/api.js', gapiLoaded);
    loadScript('https://accounts.google.com/gsi/client', initializeGisClient);
  }, []);

  // 处理授权点击事件
  const handleAuthClick = () => {
    tokenClient.current.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      // 获取并存储 token
      const token = window.gapi.client.getToken();
      localStorage.setItem('google_token', JSON.stringify(token));
      showButtons();
    };

    // 请求访问令牌
    if (window.gapi.client.getToken() === null) {
      // 如果没有 token，请求新的 token
      tokenClient.current.requestAccessToken({ prompt: 'consent' , expires_in: 604800 });
    } else {
      // 如果已有 token，刷新 token
      tokenClient.current.requestAccessToken({ prompt: '', expires_in: 604800  });
    }
  };

  // 处理退出登录点击事件
  const handleSignoutClick = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      // 撤销访问令牌
      window.google.accounts.oauth2.revoke(token.access_token);
      // 清除客户端的 token
      window.gapi.client.setToken('');
      // 重置 UI 状态
      uiElements.current.content.innerText = '';
      uiElements.current.authorizeButton.innerText = 'Authorize';
      uiElements.current.signoutButton.style.visibility = 'hidden';
      uiElements.current.listFoldersButton.style.visibility = 'hidden';
      uiElements.current.uploadButton.style.visibility = 'hidden';
      uiElements.current.createFolderButton.style.visibility = 'hidden';
    }
  };

  // 获取文件夹路径
  const getFolderPath = async (folderId) => {
    const accessToken = window.gapi.client.getToken().access_token;
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files/${folderId}?fields=id,name,parents`,
      {
        method: 'GET',
        headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      }
    );
    const folder = await response.json();
    if (!folder.parents) return folder.name;
    const parentPath = await getFolderPath(folder.parents[0]);
    return parentPath + '/' + folder.name;
  };

  // 列出文件夹
  const listFolders = async () => {
    let response;
    try {
      // 请求 appDataFolder 空间中的文件列表
      response = await window.gapi.client.drive.files.list({
        spaces: 'appDataFolder',
        pageSize: 100,
        fields: 'files(id, name, mimeType)',
      });
    } catch (err) {
      uiElements.current.content.innerText = err.message;
      return;
    }
    const files = response.result.files;
    if (!files || files.length === 0) {
      uiElements.current.content.innerText = '未找到文件夹。';
      return;
    }
    console.log('开始获取文件夹路径');
    setFileLoadProgressState({ value: 0, max: files.length });
    
    const folderPaths = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      console.log(`正在处理第 ${index + 1} 个文件: ${file.name}`);
      folderPaths.push({ id: file.id, name: file.name, mimeType: file.mimeType });
    }
    
    // 获取每个文件的完整路径
    for (let index = 0; index < folderPaths.length; index++) {
      const file = folderPaths[index];
      const fullPath = await getFolderPath(file.id);
      console.log(`文件 ${file.name} 的完整路径: ${fullPath}`);
      folderPaths[index].name = fullPath;
      setFileLoadProgressState(prevState => ({ ...prevState, value: index + 1 }));
    }

    console.log('所有文件夹路径获取完成');
    console.log('文件夹路径:', folderPaths);
    setFolders(folderPaths);
  };

  // 处理文件点击事件
  const handleFileClick = async (fileId, mimeType) => {
    const accessToken = window.gapi.client.getToken().access_token;
    // 获取文件内容
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
      {
        method: 'GET',
        headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      }
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setSelectedFile({ url, mimeType });
  };

  // 处理上传点击事件
  const handleUploadClick = async () => {
    const fileInput = uiElements.current.fileInput;
    const files = fileInput.files;

    if (files.length === 0) {
      uiElements.current.content.innerText = 'Please select files to upload';
      return;
    }

    const accessToken = window.gapi.client.getToken().access_token;

    for (const file of files) {
      // 准备文件元数据
      const metadata = {
        name: file.name,
        parents: [selectedFolderId || 'appDataFolder'],
        mimeType: file.type,
      };

      // 创建 multipart 请求体
      const form = new FormData();
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' })
      );
      form.append('file', file);

      // 发送上传请求
      const response = await fetch(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        {
          method: 'POST',
          headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
          body: form,
        }
      );

      const result = await response.json();
      uiElements.current.content.innerText += `File uploaded: ${result.name} (${result.id})\n`;
    }
  };

  // 处理创建文件夹点击事件
  const handleCreateFolderClick = async () => {
    // 准备文件夹元数据
    const folderMetadata = {
      name: folderName,
      mimeType: 'application/vnd.google-apps.folder',
      parents: ['appDataFolder'],
    };

    const accessToken = window.gapi.client.getToken().access_token;
    // 发送创建文件夹请求
    const response = await fetch('https://www.googleapis.com/drive/v3/files', {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: JSON.stringify(folderMetadata),
    });

    const result = await response.json();
    uiElements.current.content.innerText = 'Folder created: ' + result.name + ' (' + result.id + ')';
  };

  // 处理删除点击事件
  const handleDeleteClick = async (fileId) => {
    const accessToken = window.gapi.client.getToken().access_token;
    // 发送删除文件请求
    await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
      method: 'DELETE',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
    });
    uiElements.current.content.innerText = 'File deleted: ' + fileId;
    listFolders();
    setFileToDelete(null);
    closeModal();
  };

  // 确认删除
  const confirmDelete = (fileId) => {
    setFileToDelete(fileId);
    uiElements.current.confirmationModal.checked = true;
  };

  // 关闭确认模态框
  const closeModal = () => {
    setFileToDelete(null);
    uiElements.current.confirmationModal.checked = false;
  };

  return (
    <GoogDriveManagerLayout
      handleAuthClick={handleAuthClick}
      handleSignoutClick={handleSignoutClick}
      listFolders={listFolders}
      handleUploadClick={handleUploadClick}
      folderName={folderName}
      setFolderName={setFolderName}
      handleCreateFolderClick={handleCreateFolderClick}
      selectedFolderId={selectedFolderId}
      setSelectedFolderId={setSelectedFolderId}
      folders={folders}
      handleFileClick={handleFileClick}
      confirmDelete={confirmDelete}
      fileToDelete={fileToDelete}
      handleDeleteClick={handleDeleteClick}
      closeModal={closeModal}
      selectedFile={selectedFile}
      repeatCount={repeatCount}
      setRepeatCount={setRepeatCount}
      fileLoadProgressState={fileLoadProgressState}
    />
  );
};

export default GoogDriveListSave;
