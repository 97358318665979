import React, { useState, useEffect, useRef, useCallback } from 'react';
import AudioRegionPlayer from '../englishAudioRP/AudioRegionPlayer';
import {
  initGapiClient,
  initTokenClient,
  loadGapiScript,
  loadGisScript,
  fetchFileFromDrive,
  checkInitialization,
  isGapiTokenExpired,
  clearGapiExpiredToken,
} from './GoogleDriveCommon';
import { loadFfmpeg, convertM4aToWav } from './MyFFmpeg';

const DriveFileViewerGoogleDrive = () => {
  const [tokenClient, setTokenClient] = useState(null);
  const [folders, setFolders] = useState({});
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [repeatCount, setRepeatCount] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [localFiles, setLocalFiles] = useState([]);
  const audioRef = useRef(null);
  const messageRef = useRef(null);
  const playCountRef = useRef(0);

  useEffect(() => {
    const initScripts = async () => {
      try {
        console.log('isGapiTokenExpired:');
        console.log(isGapiTokenExpired);

        await loadGapiScript(
          initGapiClient,
          setAuthorized,
          listFiles,
          initTokenClient,
          setError,
          setTokenClient
        );
        await loadGisScript(setError);
      } catch (error) {
        console.error(error);
      }
    };

    initScripts();
    loadFfmpeg(messageRef);
  }, []);

  const handleAudioEnded = useCallback(() => {
    playCountRef.current += 1;
    if (playCountRef.current < repeatCount) {
      audioRef.current.play();
    }
  }, [repeatCount]);

  useEffect(() => {
    console.log(
      'useEffect: playbackRate, repeatCount, selectedFile, handleAudioEnded'
    );
    const audioElement = audioRef.current;
    console.log(`audioElement:${audioElement}`);
    console.log(audioElement);
    if (audioElement) {
      audioElement.playbackRate = playbackRate;
      audioElement.loop = false;
      playCountRef.current = 0;
      audioElement.addEventListener('ended', handleAudioEnded);
    }
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, [playbackRate, repeatCount, selectedFile, handleAudioEnded]);

  const handleAuthClick = () => {
    if (tokenClient) {
      tokenClient.requestAccessToken({ prompt: 'consent', expires_in: 604800 });
    } else {
      setError('Token client not initialized');
    }
  };

  const listFiles = (folderId = 'appDataFolder') => {
    setLoading(true);
    setError(null);
    window.gapi.client.drive.files
      .list({
        spaces: 'appDataFolder',
        q: `'${folderId}' in parents and trashed = false`,
        fields: 'files(id, name, mimeType)',
      })
      .then((response) => {
        const files = response.result.files;
        setFolders((prevFolders) => ({
          ...prevFolders,
          [folderId]: files,
        }));
        setLoading(false);
      })
      .catch((err) => {
        setError('Error listing files');
        if (isGapiTokenExpired()) {
          clearGapiExpiredToken();
        }
        setLoading(false);
      });
  };

  const handleFolderClick = (folderId) => {
    if (!folders[folderId]) {
      listFiles(folderId);
    }
    setCurrentFolderId(folderId);
  };

  const handleFileClick = async (file) => {
    console.log(`handleFileClick: {`);
    console.log("file:")
    console.log("file.url:")
    console.log(file.url)
    console.log("file.name:")
    console.log(file.name)
    console.log("file.id:")
    console.log(file.id)

    try {
      console.log(`handleFileClick file.isLocal ${file.isLocal}`);
      console.log(file);
      if (file.isLocal) {
        setSelectedFile(file);
      } else {
        checkInitialization(
          window.gapi.client.getToken().access_token,
          'gapi access_token',
          setError
        );
        const blob = await fetchFileFromDrive(file.id);
        console.log(`handleFileClick blob:`);
        console.log(blob);
        console.log(file.name);

        const cachedFile = cacheFile(blob, file.name);
        console.log('cachedFile:');
        console.log(cachedFile);
        setSelectedFile({ ...file, ...cachedFile });
      }
    } catch (err) {
      setError('Error fetching file');
    }
    console.log(`handleFileClick: }`);
  };

  const handleLocalFileChange = (event) => {
    console.log("handleLocalFileChange: { ")

    const files = event.target.files;
    const localFilesList = Array.from(files).map((file) => {
      const url = URL.createObjectURL(file);
      console.log("------")
      console.log("file:")
      console.log(file)

      console.log(`file.id : ${file.id}`)
      console.log(`file.name : ${file.name}`)
      console.log(`file.type : ${file.type}`)
      return {
        id: file.name,
        name: file.name,
        mimeType: file.type,
        url,
        isLocal: true,
      };
    });
    setLocalFiles(localFilesList);
  };

  const cacheFile = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    return { url, fileName };
  };

  const renderFileContent = (file) => {
    if (!selectedFile || selectedFile.id !== file.id) {
      return null;
    }

    if (file.mimeType.startsWith('image/')) {
      return (
        <div className="card w-full bg-base-100 shadow-xl">
          <figure>
            <img
              src={selectedFile.url}
              alt={file.name}
              className="object-contain max-w-full max-h-screen"
            />
          </figure>
        </div>
      );
    }

    if (
      file.mimeType === 'audio/mpeg' ||
      file.mimeType === 'audio/x-m4a' ||
      file.mimeType === 'audio/wav'
    ) {
      return (
        <div>
          <br></br>
          <div className="w-full bg-gray-100 p-4">
            <div></div>
            <div>
              <h1>WaveSurfer Demo in A2</h1>
              <AudioRegionPlayer audioUrl={selectedFile.url} />
            </div>
            <div className="mt-2">
              <audio
                controls
                ref={audioRef}
                src={selectedFile.url}
                className="w-full"
              />
              <label htmlFor="repeatCount" className="label">
                <span className="label-text">Repeat Count:</span>
              </label>
              <select
                id="repeatCount"
                value={repeatCount}
                onChange={(e) => setRepeatCount(Number(e.target.value))}
                className="select select-bordered"
              >
                {[...Array(10).keys()].map((n) => (
                  <option key={n + 1} value={n + 1}>
                    {n + 1}
                  </option>
                ))}
              </select>
              <label htmlFor="playbackRate" className="label">
                <span className="label-text">Playback Speed:</span>
              </label>
              <select
                id="playbackRate"
                value={playbackRate}
                onChange={(e) => setPlaybackRate(Number(e.target.value))}
                className="select select-bordered"
              >
                {[0.5, 1, 1.5, 2].map((rate) => (
                  <option key={rate} value={rate}>
                    {rate}x
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <input
          type="file"
          multiple
          onChange={handleLocalFileChange}
          className="file-input file-input-bordered file-input-primary w-full max-w-xs"
        />
      </div>
      {error && <div className="alert alert-error shadow-lg">{error}</div>}
      <button
        className="btn btn-primary"
        onClick={handleAuthClick}
        disabled={authorized}
      >
        {authorized ? 'Authorized' : 'Authorize'}
      </button>
      <div>
        <button
          className="btn btn-secondary mb-4"
          onClick={() => listFiles(currentFolderId || 'appDataFolder')}
        >
          Refresh Files
        </button>
        {currentFolderId && (
          <button
            className="btn btn-accent mb-4"
            onClick={() => setCurrentFolderId(null)}
          >
            Back to Root
          </button>
        )}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ul className="w-full">
            {[
              ...localFiles,
              ...(folders[currentFolderId || 'appDataFolder'] || []),
            ].map((file) => (
              <li key={file.id} className="my-2">
                <div className="flex items-center justify-between">
                  <div onClick={() => handleFileClick(file)}>
                    {file.mimeType === 'application/vnd.google-apps.folder' ? (
                      <button
                        className="btn btn-link"
                        onClick={() => handleFolderClick(file.id)}
                      >
                        {file.name}
                      </button>
                    ) : (
                      <div>
                      <span className="cursor-pointer">{file.name}</span>
                      <br/>
                      <span className="cursor-pointer">{file.url}</span>
                      </div>
                    )}
                  </div>
                </div>
                {renderFileContent(file)}
                {selectedFile && selectedFile.id === file.id && (
                  <div className="flex space-x-4 mt-2">
                    {(file.mimeType === 'audio/mpeg' ||
                      file.mimeType === 'audio/x-m4a') && (
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          convertM4aToWav(file, fetchFileFromDrive)
                        }
                      >
                        Convert to WAV
                      </button>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <p ref={messageRef}></p>
    </div>
  );
};

export default DriveFileViewerGoogleDrive;
