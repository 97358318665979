// GoogleDriveCommon.js
const CLIENT_ID =
  '1085518854241-r4uvt7rpu6uh9hv1ciedgv6g6tl3ro33.apps.googleusercontent.com';
const API_KEY = 'AIzaSyDyev_rOmTm5N9Df2wYegy3fuQXnQJ5rz8';
const SCOPES = 'https://www.googleapis.com/auth/drive.appdata';


const isGapiTokenExpired = () => {
  const token = window.gapi.client.getToken();
  if (!token || !token.access_token) {
    return true;
  }
  const expiresIn = token.expires_in;
  const issuedAt = localStorage.getItem('gapi_token_issued_at');
  const now = Math.floor(Date.now() / 1000);

  console.log(`isGapiTokenExpired now       : ${now}`);
  console.log(`isGapiTokenExpired issuedAt  : ${issuedAt}`);
  console.log(`isGapiTokenExpired expiresIn : ${expiresIn}`);
  console.log(`isGapiTokenExpired now - issuedAt : ${now - issuedAt}`);

  return (now - issuedAt) >= expiresIn;
};

const clearGapiExpiredToken = (setAuthorized) => {
  const token = window.gapi.client.getToken();
  if (!token || !token.access_token) {
    return;
  }
  const expiresIn = token.expires_in;
  const issuedAt = localStorage.getItem('gapi_token_issued_at');
  const now = Math.floor(Date.now() / 1000);

  if ((now - issuedAt) >= expiresIn) {
    localStorage.removeItem('gapiToken');
    localStorage.removeItem('gapi_token_issued_at');
    window.gapi.client.setToken(null);
    setAuthorized(false);
  }
};


const initGapiClient = (
  setAuthorized,
  listFiles,
  initTokenClient,
  setError,
  setTokenClient
) => {
  window.gapi.client
    .init({
      apiKey: API_KEY,
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      ],
    })
    .then(() => {
      const storedToken = localStorage.getItem('gapiToken');
      if (storedToken) {
        const token = JSON.parse(storedToken);
        window.gapi.client.setToken(token);
        setAuthorized(true);
        listFiles(); // Load the root folder files after setting the token
      } else {
        const client = initTokenClient(setAuthorized, listFiles, setError);
        setTokenClient(client);
      }
    })
    .catch((err) => {
      setError('Error loading GAPI client');
    });
};

const initTokenClient = (setAuthorized, listFiles, setError) => {
  try {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (tokenResponse) => {
        if (tokenResponse.error) {
          throw new Error(tokenResponse.error);
        }
        // 添加 issued_at 字段
        tokenResponse.issued_at = Math.floor(Date.now() / 1000); // 当前时间戳（秒）
        window.gapi.client.setToken(tokenResponse);
        localStorage.setItem('gapiToken', JSON.stringify(tokenResponse));
        localStorage.setItem('gapi_token_issued_at', Math.floor(Date.now() / 1000));
        setAuthorized(true);
        listFiles(); // Load the root folder files after authentication
      },
      expires_in: 604800,
    });
    return client;
  } catch (err) {
    setError('Error initializing token client');
    return null;
  }
};


const loadGapiScript = (
  initGapiClient,
  setAuthorized,
  listFiles,
  initTokenClient,
  setError,
  setTokenClient
) => {
  return new Promise((resolve, reject) => {
    loadScript(
      'https://apis.google.com/js/api.js',
      () => {
        window.gapi.load('client', () => {
          initGapiClient(
            setAuthorized,
            listFiles,
            initTokenClient,
            setError,
            setTokenClient
          );
          resolve();
        });
      },
      () => {
        setError('Error loading GAPI script');
        reject('Error loading GAPI script');
      }
    );
  });
};

const loadGisScript = (setError) => {
  return new Promise((resolve, reject) => {
    loadScript(
      'https://accounts.google.com/gsi/client',
      () => {
        resolve();
      },
      () => {
        setError('Error loading GIS script');
        reject('Error loading GIS script');
      }
    );
  });
};

const loadScript = (src, onLoad, onError) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = onLoad;
  script.onerror = onError;
  document.body.appendChild(script);
};

const fetchFileFromDrive = async (fileId) => {
  const accessToken = window.gapi.client.getToken().access_token;
  const response = await fetch(
    `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
    {
      method: 'GET',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
    }
  );
  const blob = await response.blob();
  return blob;
};

const checkInitialization = (variable, variableName, setError) => {
  if (!variable) {
    setError(`${variableName} not initialized`);
    throw new Error(`${variableName} not initialized`);
  }
};

export {

  isGapiTokenExpired,
  clearGapiExpiredToken,
  initGapiClient,
  initTokenClient,
  loadGapiScript,
  loadGisScript,
  loadScript,
  fetchFileFromDrive,
  checkInitialization,
};
