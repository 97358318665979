// A2.js

import React from 'react';
import AudioRegionPlayer from './AudioRegionPlayer';

const AudioRegionPlayerSample = () => {
  return (
    <div>
      <h1>AudioRegionPlayer Sample</h1>
      <AudioRegionPlayer audioUrl="/examples_audio_audio.wav" />
    </div>
  );
};

export default AudioRegionPlayerSample;
