class WordFrequency {
  constructor() {
    this.frequencyMap = new Map();
    this.initialized = false;
  }

  static BASE_PATH = window.location.origin;
  static CSV_PATH = `${WordFrequency.BASE_PATH}/data/matched_frequencies.csv`;
  static instance = null;

  // 单例模式，确保只加载一次数据
  static async getInstance() {
    if (!WordFrequency.instance) {
      WordFrequency.instance = new WordFrequency();
      await WordFrequency.instance.init();
    }
    return WordFrequency.instance;
  }

  async init() {
    if (this.initialized) return;
    
    try {
      const response = await fetch(WordFrequency.CSV_PATH);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const csvText = await response.text();
      this.loadFrequencyData(csvText);
      this.initialized = true;
    } catch (error) {
      console.error('加载词频数据失败:', error);
      throw error;
    }
  }

  loadFrequencyData(csvText) {
    const lines = csvText.split('\n');
    // 跳过标题行
    let processedCount = 0;
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (!line) continue;
      
      const [frequency, word] = line.split(',');
      if (word) {
        // 将单个频率值改为包含频率和排序位置的对象
        this.frequencyMap.set(word, {
          freq: parseFloat(frequency),
          rank: processedCount + 1  // 添加排序位置，从1开始计数
        });
        processedCount++;
        
        if (processedCount % 1000 === 0) {
          console.log(`已处理 ${processedCount} 个词频数据`);
        }
      }
    }
    console.log(`词频数据加载完成，共处理 ${processedCount} 个词条`);
  }

  getFrequency(word) {
    // 返回完整的频率信息对象，包含频率(freq)和排名(rank)
    return this.frequencyMap.get(word) || { freq: 0, rank: 0 };
  }
}

export default WordFrequency;
