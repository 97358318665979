import React, { useState, useRef } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

function AudioProcessor() {
    const [loaded, setLoaded] = useState(false);
    const [inputUrl, setInputUrl] = useState('');
    const [inputFormat, setInputFormat] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const ffmpegRef = useRef(new FFmpeg());
    const audioRef = useRef(null);
    const messageRef = useRef(null);

    const load = async () => {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const ffmpeg = ffmpegRef.current;
        ffmpeg.on('log', ({ message }) => {
            messageRef.current.innerHTML = message;
            console.log(message);
        });
        console.log('Loading FFmpeg core...');
        await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
        setLoaded(true);
        console.log('FFmpeg core loaded.');
    };

    const transcode = async () => {
        console.log('transcode: {');
        const ffmpeg = ffmpegRef.current;

        console.log(`ffmpeg.writeFile: input.${inputFormat} , inputUrl: ${inputUrl}`);
        await ffmpeg.writeFile(`input.${inputFormat}`, await fetchFile(inputUrl));
        console.log(`Input file written: input.${inputFormat}`);
        await ffmpeg.exec(['-i', `input.${inputFormat}`, `output.${inputFormat}`]);
        console.log('Transcoding complete.');
        const data = await ffmpeg.readFile(`output.${inputFormat}`);
        const blob = new Blob([data.buffer], { type: `audio/${inputFormat}` });
        const url = URL.createObjectURL(blob);
        console.log(`transcode: ${url}`);

        // Set the audio source
        audioRef.current.src = url;

        // Create a download link and click it programmatically
        const link = document.createElement('a');
        link.href = url;
        link.download = `output.${inputFormat}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log('transcode: }');
    };

    const timestretch = async () => {
        console.log('timestretch: {');
        if (!inputUrl) {
            alert('Please enter a valid URL');
            return;
        }
        console.log('Starting timestretch process...');
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.writeFile(`input.${inputFormat}`, await fetchFile(inputUrl));
        console.log(`Input file written: input.${inputFormat}`);
        await ffmpeg.exec(['-i', `input.${inputFormat}`, '-filter:a', 'atempo=0.5', `output_stretched.${inputFormat}`]);
        console.log('Timestretching complete.');
        const data = await ffmpeg.readFile(`output_stretched.${inputFormat}`);
        const blob = new Blob([data.buffer], { type: `audio/${inputFormat}` });
        const url = URL.createObjectURL(blob);

        // Set the audio source
        audioRef.current.src = url;

        // Create a download link and click it programmatically
        const link = document.createElement('a');
        link.href = url;
        link.download = `output_stretched.${inputFormat}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log('Audio timestretched and download link created.');
        console.log('timestretch: }');
    };

    const cutAudio = async () => {
        if (!inputUrl || !startTime || !endTime) {
            alert('Please enter a valid URL and time range');
            return;
        }
        console.log('Starting audio cut process...');
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.writeFile(`input.${inputFormat}`, await fetchFile(inputUrl));
        console.log(`Input file written: input.${inputFormat}`);
        await ffmpeg.exec(['-i', `input.${inputFormat}`, '-ss', startTime, '-to', endTime, '-c', 'copy', `output_cut.${inputFormat}`]);
        console.log('Audio cutting complete.');
        const data = await ffmpeg.readFile(`output_cut.${inputFormat}`);
        const blob = new Blob([data.buffer], { type: `audio/${inputFormat}` });
        const url = URL.createObjectURL(blob);

        // Set the audio source
        audioRef.current.src = url;

        // Create a download link and click it programmatically
        const link = document.createElement('a');
        link.href = url;
        link.download = `output_cut.${inputFormat}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log('Audio cut and download link created.');
    };

    return (
        loaded ? (
            <>
                <audio ref={audioRef} controls></audio><br/>
                <button onClick={transcode}>Convert Audio</button>
                <br/>
                <button onClick={timestretch}>Convert Audio to 0.5x</button>
                <br/>
                <button onClick={cutAudio}>Cut Audio</button>
                <br/>
                <input 
                    type="text" 
                    value={inputUrl} 
                    onChange={(e) => setInputUrl(e.target.value)} 
                    placeholder="Enter URL: for example: blob:https...." 
                />
                <br/>
                <input 
                    type="text" 
                    value={inputFormat} 
                    onChange={(e) => setInputFormat(e.target.value)} 
                    placeholder="Enter Input Format (e.g., m4a, mp3)" 
                />
                <br/>
                <input 
                    type="text" 
                    value={startTime} 
                    onChange={(e) => setStartTime(e.target.value)} 
                    placeholder="Start Time (e.g., 00:00:10)" 
                />
                <br/>
                <input 
                    type="text" 
                    value={endTime} 
                    onChange={(e) => setEndTime(e.target.value)} 
                    placeholder="End Time (e.g., 00:00:20)" 
                />
                <br/>
                <p ref={messageRef}></p>
            </>
        ) : (
            <button onClick={load}>Load ffmpeg-core (~31 MB)</button>
        )
    );
}

export default AudioProcessor;
