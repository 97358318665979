// src/FlashWord.js
import React, { useState } from 'react';
import './FlashWord.css';

const FlashWord = () => {
  const [words, setWords] = useState([]);
  const [results, setResults] = useState([]);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [randomWord, setRandomWord] = useState(null);
  const [importData, setImportData] = useState('');

  const addWord = (word) => {
    setWords((prevWords) => {
      const existingWordIndex = prevWords.findIndex((w) => w.text === word.text);
      let updatedWords;
      if (existingWordIndex !== -1) {
        updatedWords = prevWords.map((w, index) =>
          index === existingWordIndex ? { ...w, ...word } : w
        );
      } else {
        const newWord = { ...word, id: Date.now() };
        updatedWords = [...prevWords, newWord];
      }
      console.log('All words:', JSON.stringify(updatedWords, null, 2));
      return updatedWords;
    });
  };

  const importWords = () => {
    try {
      const parsedData = JSON.parse(importData);
      setWords(parsedData);
      console.log('Imported words:', parsedData);
    } catch (error) {
      console.error('Error importing words:', error);
    }
  };

  const deleteWord = (id) => {
    setWords((prevWords) => {
      const updatedWords = prevWords.filter((word) => word.id !== id);
      console.log('All words:', JSON.stringify(updatedWords, null, 2));
      return updatedWords;
    });
  };

  const updateWord = (id, newWord) => {
    setWords((prevWords) => {
      const updatedWords = prevWords.map((word) => (word.id === id ? newWord : word));
      console.log('All words:', JSON.stringify(updatedWords, null, 2));
      return updatedWords;
    });
  };

  const searchWord = (query) => {
    const result = words.filter((word) => word.text.includes(query));
    setResults(result);
  };

  const displayRandomWord = () => {
    const remainingWords = words.filter((word) => !displayedWords.includes(word.id));
    if (remainingWords.length === 0) {
      setDisplayedWords([]);
      setRandomWord(null);
    } else {
      const randomIndex = Math.floor(Math.random() * remainingWords.length);
      const wordToDisplay = remainingWords[randomIndex];
      setDisplayedWords((prevDisplayedWords) => [...prevDisplayedWords, wordToDisplay.id]);
      setRandomWord(wordToDisplay);
    }
  };

  return (
    <div className="App">
      <h1>Word Flashcards</h1>
      <WordForm onAddWord={addWord} />
      <WordList words={words} onDeleteWord={deleteWord} onUpdateWord={updateWord} />
      <SearchForm onSearchWord={searchWord} />
      <SearchResults results={results} />
      <button onClick={displayRandomWord}>Display Random Word</button>
      {randomWord && (
        <div>
          <h2>Random Word</h2>
          <p>{randomWord.text}</p>
          {randomWord.image && <img src={randomWord.image} alt={randomWord.text} style={{ width: '100px', height: '100px' }} />}
        </div>
      )}
      <div>
        <h2>Import Words</h2>
        <textarea
          value={importData}
          onChange={(e) => setImportData(e.target.value)}
          placeholder='Paste your JSON data here'
          rows={5}
          cols={40}
        />
        <button onClick={importWords}>Import</button>
      </div>
    </div>
  );
};

const WordForm = ({ onAddWord }) => {
  const [text, setText] = useState('');
  const [image, setImage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddWord({ text, image });
    setText('');
    setImage('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Word" value={text} onChange={(e) => setText(e.target.value)} required />
      <input type="text" placeholder="Image URL" value={image} onChange={(e) => setImage(e.target.value)} />
      <button type="submit">Add Word</button>
    </form>
  );
};

const WordList = ({ words, onDeleteWord, onUpdateWord }) => {
  return (
    <ul>
      {words.map((word) => (
        <WordItem key={word.id} word={word} onDeleteWord={onDeleteWord} onUpdateWord={onUpdateWord} />
      ))}
    </ul>
  );
};

const WordItem = ({ word, onDeleteWord, onUpdateWord }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(word.text);
  const [newImage, setNewImage] = useState(word.image);

  const handleUpdate = () => {
    onUpdateWord(word.id, { ...word, text: newText, image: newImage });
    setIsEditing(false);
  };

  return (
    <li>
      {isEditing ? (
        <>
          <input type="text" value={newText} onChange={(e) => setNewText(e.target.value)} />
          <input type="text" value={newImage} onChange={(e) => setNewImage(e.target.value)} />
          <button onClick={handleUpdate}>Update</button>
        </>
      ) : (
        <>
          <span>{word.text}</span>
          {word.image && <img src={word.image} alt={word.text} style={{ width: '50px', height: '50px' }} />}
          <button onClick={() => setIsEditing(true)}>Edit</button>
          <button onClick={() => onDeleteWord(word.id)}>Delete</button>
        </>
      )}
    </li>
  );
};

const SearchForm = ({ onSearchWord }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearchWord(searchTerm);
  };

  return (
    <form onSubmit={handleSearch}>
      <input type="text" placeholder="Search Word" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      <button type="submit">Search</button>
    </form>
  );
};

const SearchResults = ({ results }) => {
  return (
    <div>
      <h2>Search Results</h2>
      {results.length === 0 ? (
        <p>No results found</p>
      ) : (
        <ul>
          {results.map((result) => (
            <li key={result.id}>
              <span>{result.text}</span>
              {result.image && <img src={result.image} alt={result.text} style={{ width: '50px', height: '50px' }} />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FlashWord;
