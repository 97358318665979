import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

const ffmpegRef = new FFmpeg();

const loadFfmpeg = async (messageRef) => {
  const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
  const ffmpeg = ffmpegRef;
  ffmpeg.on('log', ({ message }) => {
    if (messageRef.current) {
      messageRef.current.innerHTML = message;
    }
    console.log(message);
  });
  await ffmpeg.load({
    coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
  });
};

const convertM4aToWav = async (file, fetchFileFromDrive) => {
  const ffmpeg = ffmpegRef;
  const blob = await fetchFileFromDrive(file.id);
  await ffmpeg.writeFile('input.m4a', await fetchFile(blob));
  await ffmpeg.exec(['-i', 'input.m4a', 'output.wav']);
  const data = await ffmpeg.readFile('output.wav');
  const outputBlob = new Blob([data.buffer], { type: 'audio/wav' });
  const url = URL.createObjectURL(outputBlob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${file.name.split('.').slice(0, -1).join('.')}.wav`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { loadFfmpeg, convertM4aToWav };
