import { useState, useEffect } from 'react';
import * as Processor from './CustomProcessor';
import WordFrequency from '../WordListCheck/WordFrequency';

// 定义 WordItem 类型
class WordItem {
  constructor(word = '', count = 0, length = 0, isLearned = false) {
    this.word = word;
    this.count = count;
    this.length = length;
    this.isLearned = isLearned;
  }
}

export function useEnglishArticleTextProcessor() {
  const [text, setText] = useState('');
  const [wordCounts, setWordCounts] = useState({});
  const [filteredWords, setFilteredWords] = useState([]);
  const [sortCriteriaTable, setSortCriteriaTable] = useState([]);
  const [sortedWordsText, setSortedWordsText] = useState('');
  const [learnedWords, setLearnedWords] = useState(() => {
    // 从本地存储加载已学会的单词
    const storedLearnedWords = localStorage.getItem('learnedWords');
    if (storedLearnedWords) {
      try {
        const parsedLearnedWords = JSON.parse(storedLearnedWords);
        if (typeof parsedLearnedWords === 'object' && parsedLearnedWords !== null) {
          return parsedLearnedWords;
        }
      } catch (error) {
        console.error('解析 learnedWords 时出错:', error);
      }
    }
    return {};
  });
  const [learnedWordsPercentage, setLearnedWordsPercentage] = useState(0);
  const [wordFrequency, setWordFrequency] = useState(null);

  const handleProcessText = () => {
    const words = Processor.splitTextIntoWords(text);
    const { cleaned, filtered } = Processor.cleanWords(words);
    const counts = Processor.countWordOccurrences(cleaned);
    setWordCounts(counts);
    setFilteredWords(filtered);
  };

  const getSortedData = () => {
    const sortedData = Object.entries(wordCounts).sort((a, b) => {
      for (let { columnName, isDescending } of sortCriteriaTable) {
        let comparison = 0;

        if (columnName === 'word') {
          comparison = a[0].localeCompare(b[0]);
        } else if (columnName === 'count') {
          comparison = a[1] - b[1];
        } else if (columnName === 'length') {
          comparison = a[0].length - b[0].length;
        }

        if (comparison !== 0) {
          return isDescending ? -comparison : comparison;
        }
      }
      return 0;
    });
    
    return sortedData.map(([word, count]) => ({
      ...new WordItem(word, count, word.length, learnedWords[word] || false),
      frequency: wordFrequency ? wordFrequency.getFrequency(word) : 0
    }));
  };

  const calculateLearnedWordsPercentage = (sortedData) => {
    if (sortedData.length === 0) return 0;
    const learnedCount = sortedData.filter(item => item.isLearned).length;
    return (learnedCount / sortedData.length) * 100;
  };

  useEffect(() => {
    const sortedData = getSortedData();
    const sortedWords = sortedData.map(({ word }) => word).join(', ');
    setSortedWordsText(sortedWords);
  }, [wordCounts, sortCriteriaTable]);

  const handleAddSortCriterion = () => {
    setSortCriteriaTable([...sortCriteriaTable, { columnName: 'word', isDescending: false }]);
  };

  const handleRemoveSortCriterion = (index) => {
    const updatedCriteria = [...sortCriteriaTable];
    updatedCriteria.splice(index, 1);
    setSortCriteriaTable(updatedCriteria);
  };
  const handleMoveUp = (index) => {
    if (index > 0) {
      const updatedCriteria = [...sortCriteriaTable];
      [updatedCriteria[index - 1], updatedCriteria[index]] = [updatedCriteria[index], updatedCriteria[index - 1]];
      setSortCriteriaTable(updatedCriteria);
    }
  };

  const handleMoveDown = (index) => {
    if (index < sortCriteriaTable.length - 1) {
      const updatedCriteria = [...sortCriteriaTable];
      [updatedCriteria[index], updatedCriteria[index + 1]] = [updatedCriteria[index + 1], updatedCriteria[index]];
      setSortCriteriaTable(updatedCriteria);
    }
  };

  const handleColumnNameChange = (index, newColumnName) => {
    const updatedCriteria = [...sortCriteriaTable];
    updatedCriteria[index].columnName = newColumnName;
    setSortCriteriaTable(updatedCriteria);
  };

  const handleSortOrderChange = (index, isDescending) => {
    const updatedCriteria = [...sortCriteriaTable];
    updatedCriteria[index].isDescending = isDescending;
    setSortCriteriaTable(updatedCriteria);
  };

  const handleLearnedToggle = (word) => {
    setLearnedWords(prevState => ({
      ...prevState,
      [word]: !prevState[word]
    }));
  };

  useEffect(() => {
    // 保存已学会的单词到本地存储
    try {
      localStorage.setItem('learnedWords', JSON.stringify(learnedWords));
    } catch (error) {
      console.error('保存 learnedWords 到本地存储时出错:', error);
    }
  }, [learnedWords]);

  const handleSaveLearnedWords = () => {
    const learnedWordsArray = Object.entries(learnedWords)
      .filter(([_, isLearned]) => isLearned)
      .map(([word]) => word);
    
    const content = learnedWordsArray.join('\n');
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = 'learned_words.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleImportLearnedWords = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const importedWords = content.split('\n').filter(word => word.trim() !== '');
        setLearnedWords(prevState => {
          const newState = { ...prevState };
          importedWords.forEach(word => {
            newState[word.trim()] = true;
          });
          return newState;
        });
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    const sortedData = getSortedData();
    const percentage = calculateLearnedWordsPercentage(sortedData);
    setLearnedWordsPercentage(percentage);
  }, [wordCounts, learnedWords]);

  // 添加初始化词频数据的 useEffect
  useEffect(() => {
    const initWordFrequency = async () => {
      try {
        const instance = await WordFrequency.getInstance();
        setWordFrequency(instance);
      } catch (error) {
        console.error('初始化词频数据失败:', error);
      }
    };
    initWordFrequency();
  }, []);

  return {
    text,
    setText,
    wordCounts,
    filteredWords,
    sortCriteriaTable,
    sortedWordsText,
    handleProcessText,
    handleAddSortCriterion,
    handleRemoveSortCriterion,
    handleMoveUp,
    handleMoveDown,
    handleColumnNameChange,
    handleSortOrderChange,
    getSortedData,
    handleLearnedToggle,
    handleSaveLearnedWords,
    handleImportLearnedWords,
    learnedWordsPercentage,
    wordFrequency,
  };
}
