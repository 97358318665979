// TTSPage.html.js
import React, { useState, useEffect } from 'react';

const TTSPageLayoutHtml = ({
  text,
  setText,
  speed,
  setSpeed,
  isSsml,
  setIsSsml,
  format,
  setFormat,
  startSynthesis,
  isStartSynthesisAsyncButtonEnable,
  handlePause,
  isPausedEnable,
  handleResume,
  isResumeButtonEnable,
  downloadSynthesis,
  isDownloadButtonEnable,
  configs,
  selectedConfig,
  handleConfigChange,
  currentConfigDetails,
  results,
  events,
  highlightText,
  setHighlightText,
  audioPlayerUrl // 新增属性
}) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    console.log(`useEffect highlightedText: ${highlightText}`);
    if (highlightText) {
      const regex = new RegExp(`(${highlightText})`, 'gi');
      const highlightedContent = text.replace(regex, '<span class="highlight" style="background-color: yellow; color: black;">$1</span>');
      setDisplayText(highlightedContent);
    } else {
      // setDisplayText(text);
    }
  }, [text, highlightText]);

  useEffect(() => {
    if (text.trim().startsWith('<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"')) {
      setIsSsml(true);
    }
  }, [text, setIsSsml]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">TTS页面</h1>
      <div className="mb-4">
        <label className="block mb-2">要合成的文本：</label>
        <textarea
          className="textarea textarea-bordered w-full"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ height: 'auto' }}
          rows={text.split('\n').length}
        />
      </div>
      <div className="mb-4 flex items-center space-x-4">
        <div className="flex items-center">
          <label className="mr-2">速度：</label>
          <input
            type="range"
            min="0.1"
            max="2"
            step="0.1"
            value={speed}
            onChange={(e) => setSpeed(e.target.value)}
            className="slider"
          />
          <span className="ml-2">{speed}</span>
        </div>
        <div className="flex items-center">
          <label className="mr-2">
            是否为SSML：
            <a href="https://learn.microsoft.com/zh-cn/azure/ai-services/speech-service/speech-synthesis-markup" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline ml-2">
              点击此处了解更多
            </a>
          </label>
          <input
            type="checkbox"
            checked={isSsml}
            onChange={(e) => setIsSsml(e.target.checked)}
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2">格式：</label>
          <select
            className="select select-bordered"
            value={format}
            onChange={(e) => setFormat(e.target.value)}
          >
            <option value="Audio24Khz160KBitRateMonoMp3">Audio24Khz160KBitRateMonoMp3</option>
            <option value="Riff24Khz16BitMonoPcm">Riff24Khz16BitMonoPcm</option>
            <option value="Ogg24Khz16BitMonoOpus">Ogg24Khz16BitMonoOpus</option>
            <option value="Webm24Khz16BitMonoOpus">Webm24Khz16BitMonoOpus</option>
          </select>
        </div>
        <div className="flex items-center">
          <label className="mr-2">选择配置键：</label>
          <select
            className="select select-bordered"
            value={selectedConfig}
            onChange={handleConfigChange}
          >
            <option value="">选择一个配置</option>
            {configs.map((config) => (
              <option key={config.name} value={config.name}>
                {config.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <p className="mb-4">（支持播放的格式：riff pcm、mp3、ogg和webm）</p>
      <button
        className="btn btn-primary mr-2"
        onClick={startSynthesis}
        disabled={isStartSynthesisAsyncButtonEnable}
      >
        开始合成
      </button>
      <button
        className="btn btn-secondary mr-2"
        onClick={handlePause}
        disabled={isPausedEnable}
      >
        暂停
      </button>
      <button
        className="btn btn-secondary mr-2"
        onClick={handleResume}
        disabled={isResumeButtonEnable}
      >
        继续
      </button>
      <button
        className="btn btn-secondary"
        onClick={downloadSynthesis}
        disabled={isDownloadButtonEnable}
      >
        下载
      </button>
      <div className="mb-4">
        <label className="block mb-2">结果：</label>
        <textarea
          className="textarea textarea-bordered w-full"
          value={results}
          readOnly
        />
      </div>
      
      {/* 新增音频播放器组件 */}
      {audioPlayerUrl && (
        <div className="mb-4">
          <label className="block mb-2">音频播放：</label>
          <audio controls src={audioPlayerUrl} className="w-full"></audio>
        </div>
      )}

      <div className="mb-4">
        <label className="block mb-2">高亮显示：</label>
        <div 
          id="highlightDiv" 
          className="textarea textarea-bordered w-full"
          dangerouslySetInnerHTML={{ __html: displayText }}
          style={{ minHeight: '100px', padding: '10px' }}
        ></div>
      </div>
      <div className="mb-4">
        <label className="block mb-2">事件：</label>
        <textarea
          placeholder=""
          className="textarea textarea-bordered w-full"
          value={events}
          style={{ height: 'auto' }}
          rows={events.split('\n').length}
          readOnly
        ></textarea>
      </div>
      
      {selectedConfig && (
        <div className="mb-4">
          <label className="block mb-2">配置详情：</label>
          <textarea
            className="textarea textarea-bordered w-full h-64"
            value={JSON.stringify(currentConfigDetails, null, 2)}
            readOnly
          />
        </div>
      )}
    </div>
  );
};

export default TTSPageLayoutHtml;
