// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import FlashWord from './FlashWord/FlashWord';
import AzureTTS from './AzureTTS/AzureTTS';
import TTSPage from './EnglishTTS/tts/TTSPage';
import TTSConfigPage from './EnglishTTS/config/TTSConfigPage';
import GoogDriveListSave from './GoogleDrive/GoogDriveListSave';
import DriveFileViewerGoogleDrive from './R2/DriveFileViewerGoogleDrive';
import TimeZoneAlign from './TimeZoneAlign/TimeZoneAlign';
import AudioProcessor from './AudioProcessor/AudioProcessor';
import AudioRegionPlayerSample from './englishAudioRP/AudioRegionPlayerSample';
import EnglishJM from './EnglishJM/EnglishJM';
import EchartDependency from './Dependency/EchartDependency';
import DependencyVisjsGraph from './Dependency/DependencyVisjsGraph';
import EnglishArticleTextProcessorView from './Article/EnglishArticleTextProcessorView';
import WordListCheck from './WordListCheck/WordListCheck';
import YouglishWidget from './fun/YouglishWidget';
import JsonForamt from './JsonFormat/JsonFormat';


import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <nav>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>英语学习</th>
                <th>语音合成</th>
                <th>音频处理</th>
                <th>Google Drive</th>
                <th>时区</th>
                <th>依赖图</th>
                <th>JsonFormat</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link to="/flash-word">Flash Word</Link>
                </td>
                <td>
                  <Link to="/Azure-TTS">AzureTTS</Link>
                </td>
                <td>
                  <Link to="/AudioProcessor">AudioProcessor</Link>
                </td>
                <td>
                  <Link to="/GoogDriveListSave">GoogDriveListSave</Link>
                </td>
                <td>
                  <Link to="/TimeZoneAlign">TimeZoneAlign</Link>
                </td>
                <td>
                  <Link to="/EchartDependency">EchartDependency</Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/AudioRegionPlayerSample">AudioRegionPlayerSample</Link>
                </td>
                <td>
                  <Link to="/TTS-Config-Page">TTS Config</Link>
                </td>
                <td></td>
                <td>
                    <Link to="/DriveFileViewerGoogleDrive">DriveFileViewerGoogleDrive</Link>
                </td>
                <td></td>
                <td>
                  <Link to="/DependencyVisjsGraph">DependencyVisjsGraph</Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/EnglishJM">EnglishJM</Link>
                </td>
                <td>
                  <Link to="/TTS-Page">TTS Page</Link>
                </td>
                <td></td>
                <td>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Link to="/EnglishArticleTextProcessorView">EnglishArticleTextProcessorView</Link>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Link to="/WordListCheck">WordListCheck</Link>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Link to="/YouglishWidget">YouglishWidget</Link>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                <Link to="/JsonForamt">JsonForamt</Link>
                </td>
              </tr>
            </tbody>
          </table>
        </nav>

        <Routes>
          <Route path="/flash-word" element={<FlashWord />} />
          <Route path="/Azure-TTS" element={<AzureTTS />} />
          <Route path="/TTS-Config-Page" element={<TTSConfigPage />} />
          <Route path="/TTS-Page" element={<TTSPage />} />
          <Route path="/GoogDriveListSave" element={<GoogDriveListSave />} />
          <Route path="/TimeZoneAlign" element={<TimeZoneAlign />} />
          <Route path="/AudioProcessor" element={<AudioProcessor />} />
          <Route path="/DriveFileViewerGoogleDrive" element={<DriveFileViewerGoogleDrive />} />
          <Route path="/AudioRegionPlayerSample" element={<AudioRegionPlayerSample />} />
          <Route path="/EnglishJM" element={<EnglishJM />} />
          <Route path="/EchartDependency" element={<EchartDependency />} />
          <Route path="/DependencyVisjsGraph" element={<DependencyVisjsGraph />} />
          <Route path="/EnglishArticleTextProcessorView" element={<EnglishArticleTextProcessorView />} />
          <Route path="/WordListCheck" element={<WordListCheck />} />
          <Route path="/YouglishWidget" element={<YouglishWidget />} />
          <Route path="/JsonForamt" element={<JsonForamt />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
