import React from 'react';

function SortCriteriaTable({ sortCriteria, onAddCriterion, onRemoveCriterion, onMoveUp, onMoveDown, onColumnNameChange, onSortOrderChange }) {
  return (
    <div>
      <h3>Sort Criteria:</h3>
      <button className="btn btn-primary mb-4" onClick={onAddCriterion}>Add Sort Criterion</button>
      <table className="table w-auto table-zebra">
        <thead>
          <tr>
            <th className="text-center font-bold">Column Name</th>
            <th className="text-center font-bold">Descending</th>
            <th className="text-center font-bold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortCriteria.map((criterion, index) => (
            <tr key={index}>
              <td>
                <select
                  value={criterion.columnName}
                  onChange={(e) => onColumnNameChange(index, e.target.value)}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value="word">Word</option>
                  <option value="count">Count</option>
                  <option value="length">Length</option>
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={criterion.isDescending}
                  onChange={(e) => onSortOrderChange(index, e.target.checked)}
                  className="checkbox checkbox-primary"
                />
              </td>
              <td>
                <div className="flex space-x-2">
                  <button className="btn btn-secondary" onClick={() => onMoveUp(index)}>↑ Up</button>
                  <button className="btn btn-secondary" onClick={() => onMoveDown(index)}>↓ Down</button>
                  <button className="btn btn-error" onClick={() => onRemoveCriterion(index)}>✖ Remove</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SortCriteriaTable;
