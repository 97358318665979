import React, { useRef, useEffect } from 'react';

function ScrollableLayout({ children, className = '', maxHeight = '90rem' }) {
  const layoutRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (layoutRef.current && layoutRef.current.contains(e.target)) {
        e.preventDefault();
        layoutRef.current.scrollTop += e.deltaY;
      }
    };

    const currentRef = layoutRef.current;
    currentRef.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      currentRef.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div
      ref={layoutRef}
      className={`overflow-y-auto overflow-x-auto ${className}`}
      style={{ maxHeight }}
    >
      {children}
    </div>
  );
}

export default ScrollableLayout;
