import React, { useState } from 'react';
import CsvProcessor from './CsvProcessor'; // Import the class

const WordListCheck = () => {
  const [bbcNewsText, setBbcNewsText] = useState('');
  const [filteredWords, setFilteredWords] = useState([]);

  const handleBbcNewsTextChange = (e) => {
    setBbcNewsText(e.target.value);
  };

  const handleProcess = () => {
    // Load googleWordCsv from public directory
    const basePath = `${window.location.origin}`;
    const imageFilePath = `${basePath}/data/d_5_norvig.csv`;

    fetch(imageFilePath)
      .then((response) => response.text())
      .then((googleWordCsv) => {
        const csvProcessor = new CsvProcessor(bbcNewsText, googleWordCsv);
        const result = csvProcessor.processCsvData();
        setFilteredWords(result);
      })
      .catch((error) => console.error('Error fetching googleWord.csv:', error));
  };

  return (
    <div>
      <h1>CSV Word Comparison</h1>
      <div>
        <h3>Enter BBC News Words (format: civilian, launches, launcher...)</h3>
        <textarea
          value={bbcNewsText}
          onChange={handleBbcNewsTextChange}
          rows={10}
          cols={50}
        />
      </div>
      <button onClick={handleProcess}>Process Words</button>
      <div>
        <h3>Words Not Found in Google Word List</h3>
        <ul>
          {filteredWords.map((word, index) => (
            <li key={index}>{word}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WordListCheck;
