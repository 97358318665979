import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import * as UTIF from 'utif';

function EnglishJM() {
  const [charCsvData, setCharCsvData] = useState([]);
  const [wordsCsvData, setWordsCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [charMap, setCharMap] = useState({});
  const [wordImages, setWordImages] = useState({});
  const [imageFileNames, setImageFileNames] = useState({});
  const [zipInstance, setZipInstance] = useState(null);


  // Define the styles
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '', // You can adjust this height as needed
    },
    symbol: {
      // fontSize: '20px', // Font size for the symbol (e.g., 'o')
      lineHeight: '1', // Eliminate line spacing
    },
    letter: {
      // fontSize: '40px', // Font size for the letter (e.g., 'A')
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    th: {
      border: '1px solid black',
      padding: '8px',
    },
    td: {
      border: '1px solid black',
      padding: '8px',
    },
  };

  // Check if URL contains data=local
  const isLocalData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('data') === 'local';
  };

  useEffect(() => {
    if (isLocalData()) {
      // Dynamically construct paths to local files using the current URL
      const basePath = `${window.location.origin}`;
      console.log(`isLocalData ${basePath}`);
      const charCsvFilePath = `${basePath}/data/replace.csv`;
      const wordsCsvFilePath = `${basePath}/data/word_jianmingMarkUnicode.csv.csv`;
      const imageFilePath = `${basePath}/data/1.zip`;

      // Read the local files
      readLocalFile(charCsvFilePath, setCharCsvData, ',');
      readLocalFile(wordsCsvFilePath, setWordsCsvData, ';');
      readWordImageZip(imageFilePath); // Load and process ZIP file
    }
  }, []);

  useEffect(() => {
    // Transform charCsvData into a map (dictionary) for faster lookup
    const newCharMap = {};
    console.log('charCsvData');
    console.log(charCsvData);
    charCsvData.forEach((charRow) => {
      // console.log('forEach');
      // console.log('|' + charRow.JianermingAlphabetAndMark + '|');
      newCharMap[charRow.JianermingAlphabetAndMark] = charRow;
    });
    setCharMap(newCharMap);
  }, [charCsvData]);

  // Filter data before the useEffect
  const matchingData = wordsCsvData.filter(
    (row) =>
      row.EnglishWord &&
      row.EnglishWord.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredData = matchingData.slice(0, 30);

  useEffect(() => {
    const missingWords = filteredData
      .map((row) => row.EnglishWord)
      .filter((wordName) => !wordImages[wordName]);

    if (missingWords.length > 0) {
      missingWords.forEach((wordName) => loadImageForWord(wordName));
    }
  }, [filteredData, wordImages]);

  const handleFileUpload = (e, setData, delimiter) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`Uploading file: ${file.name}`);
      setLoading(true);
      Papa.parse(file, {
        complete: (result) => {
          console.log(`File content:`, result.data);
          setData(result.data);
          setLoading(false);
        },
        header: true,
        delimiter: delimiter, // Set delimiter based on file type
      });
    }
  };

  const readWordImageZip = (filePath) => {
    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(async (blob) => {
        const zip = await JSZip.loadAsync(blob);
        setZipInstance(zip);
        const newImageFileNames = {};
        Object.keys(zip.files).forEach((filename) => {
          if (!zip.files[filename].dir) {
            const imageName = filename.split('/').pop();
            const wordName = imageName.split('_')[0];
            newImageFileNames[wordName] = filename;
          }
        });
        setImageFileNames(newImageFileNames);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error reading ZIP file:', error);
      });
  };

  // useEffect(() => {
  //   console.log('imageFileNames updated:', imageFileNames);
  //   loadImageForWord('Abnaki');
  // }, [imageFileNames]);

  // Load and decode image when needed
  const loadImageForWord = async (wordName) => {
    console.log(`loadImageForWord: ${wordName}`)
    const fileName = imageFileNames[wordName];
    if (!fileName || !zipInstance) return;

    setLoading(true);

    try {
      const file = await zipInstance.file(fileName).async('arraybuffer');
      const ifds = UTIF.decode(file);
      UTIF.decodeImage(file, ifds[0]);
      const rgba = UTIF.toRGBA8(ifds[0]);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = ifds[0].width;
      canvas.height = ifds[0].height;
      const imageData = ctx.createImageData(canvas.width, canvas.height);
      imageData.data.set(rgba);
      ctx.putImageData(imageData, 0, 0);

      const imageUrl = canvas.toDataURL('image/png');
      setWordImages((prev) => ({ ...prev, [wordName]: imageUrl }));
    } catch (error) {
      console.error(`Error loading image for word ${wordName}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const readLocalFile = (filePath, setData, delimiter) => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        Papa.parse(text, {
          complete: (result) => {
            console.log(`File content:`, result.data);
            setData(result.data);
            setLoading(false);
          },
          header: true,
          delimiter: delimiter, // Set delimiter based on file type
        });
      })
      .catch((error) => {
        console.error('Error reading local file:', error);
      });
  };

  // Define the styled container component
  const MarkContainer = ({ symbol, letter }) => (
    <div style={styles.container}>
      <div style={styles.symbol}>{symbol}</div>
      <div style={styles.letter}>{letter}</div>
    </div>
  );

  // Handle ZIP file upload and extract images
  const handleZipUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`Uploading ZIP file: ${file.name}`);
      readWordImageZip(file); // Use the existing readWordImageZip function
    }
  };

  const getMarkDetails = (row, charMap) => {
    // console.log('getMarkDetails:');
    // console.log('row:');
    // console.log(row);
    const unicodeArr = row.JianMingUnicodeArr.slice(1, -1).trim();
    const charUnicodeArr = unicodeArr ? unicodeArr.split(',') : [];
    const markDetails = charUnicodeArr.map((charUnicode, index) => {
      const matchingChar = charMap[charUnicode.trim()];
      if (matchingChar) {
        // console.log('matchingChar True:');
        // console.log(matchingChar);
        // If a match is found, return the MarkContainer component
        return (
          <MarkContainer
            key={`${charUnicode}-${index}`} // Updated key to be unique
            symbol={matchingChar.Mark}
            letter={matchingChar.EnglishAlphabet}
          />
        );
      } else {
        // console.log('matchingChar False:');
        // console.log(String.fromCharCode(charUnicode.trim()));

        // If no match, return the corresponding character
        return (
          <span key={`${charUnicode}-${index}`}>
            {' '}
            {/* Updated key to be unique */}
            {String.fromCharCode(charUnicode.trim())}
          </span>
        );
      }
    });
    // console.log('getMarkDetails result:');
    // console.log(markDetails);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>{markDetails}</div>
    );
  };

  useEffect(() => {
    const loadStatsScript = () => {
      const script = document.createElement('script');
      script.onload = function () {
        const stats = new window.Stats();
        stats.showPanel(2);
        console.log(`loadStatsScript appendChild onload`);
        document.body.appendChild(stats.dom);
        requestAnimationFrame(function loop() {
          stats.update();
          requestAnimationFrame(loop);
        });
      };
      script.src = 'https://mrdoob.github.io/stats.js/build/stats.min.js';
      document.head.appendChild(script);
      console.log(`loadStatsScript appendChild`);
    };

    loadStatsScript();
  }, []);

  return (
    <div>
      <h1>CSV 文件上传与读取</h1>

      <div>
        <h2>Words CSV</h2>
        {!isLocalData() && (
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileUpload(e, setWordsCsvData, ';')} // Semicolon delimiter for Words CSV
          />
        )}
        {loading && <p>Loading...</p>}
        <br />
        <input
          type="text"
          placeholder="Search by EnglishWord"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <p>Matching words found: {matchingData.length}</p>
        <table>
          <thead>
            <tr>
              <th>EnglishWord</th> {/* Adding a new column for Mark Details */}
              <th>Mark Details</th> {/* Adding a new column for Mark Details */}
              <th>Image</th> {/* Adding a new column for Images */}
              <th>JianMingUnicodeArr</th>
            </tr>
          </thead>
          <tbody style={styles.table}>
            {filteredData.map((row, index) => {
              const markDetails = getMarkDetails(row, charMap);
              // console.log('226:');
              // console.log(markDetails);
              const imageSrc = wordImages[row.EnglishWord];
              return (
                <tr key={index}>
                  <td style={styles.td}>{row.EnglishWord}</td>
                  <td style={styles.td}>{markDetails}</td>
                  <td style={styles.td}>
                    {imageSrc ? (
                      <img src={imageSrc} alt={row.EnglishWord} style={{}} />
                    ) : (
                      'No Image'
                    )}
                  </td>
                  <td style={styles.td}>{row.JianMingUnicodeArr}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <h2>Char CSV</h2>
        {!isLocalData() && (
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileUpload(e, setCharCsvData, ',')} // Comma delimiter for Char CSV
          />
        )}
        <table style={styles.table}>
          <thead>
            <tr>
              {charCsvData.length > 0 &&
                Object.keys(charCsvData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {charCsvData.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, i) => (
                  <td style={styles.td} key={i}>
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* ZIP File Upload */}
      <div>
        <h2>Upload ZIP File</h2>
        <input type="file" accept=".zip" onChange={handleZipUpload} />
        {loading && <p>Loading ZIP file...</p>}
      </div>
    </div>
  );
}

export default EnglishJM;
