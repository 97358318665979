import React, { useEffect, useRef } from 'react';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import moment from 'moment-timezone';
import 'daisyui/dist/full.css';

const TimeZoneAlign = () => {
  const plusNyRef = useRef(null);
  const plusLosRef = useRef(null);
  const plusBeijingRef = useRef(null);

  const timelinePlusNyRef = useRef(null);
  const timelinePlusLosRef = useRef(null);
  const timelinePlusBeijingRef = useRef(null);

  useEffect(() => {
    if (
      timelinePlusNyRef.current ||
      timelinePlusLosRef.current ||
      timelinePlusBeijingRef.current
    ) {
      return;
    }

    const today = moment.utc().startOf('day');
    const start = today.clone();
    const end = today.clone().add(2, 'day');
    const customTime = today.clone().add(28, 'hour');

    const items = new DataSet([
      { id: 1, content: 'item 1', start: today.clone().add(8, 'hour') },
      { id: 2, content: 'item 2', start: today.clone().add(16, 'hour') },
      { id: 3, content: 'item 3', start: today.clone().add(32, 'hour') },
    ]);

    const synchronizeTimelines = (sourceTimeline, targetTimelines) => {
      sourceTimeline.on('rangechange', (properties) => {
        targetTimelines.forEach((timeline) => {
          timeline.setWindow(properties.start, properties.end, {
            animation: false,
          });
        });
      });
    };

    const options = {
      editable: true,
      start: start.format(),
      end: end.format(),
      moment: (date) => moment(date),
      zoomMin: 1000 * 60, // 1 hour in milliseconds
      zoomMax: 1000 * 60 * 60 * 24 * 7, // 1 week in milliseconds
      format: {
        minorLabels: {
          hour: 'HH:mm',
        },
        majorLabels: {
          day: 'MMMM DD, YYYY',
        },
      },
    };

    timelinePlusNyRef.current = new Timeline(plusNyRef.current, items, {
      ...options,
      moment: (date) => moment(date).tz('America/New_York'),
    });
    timelinePlusNyRef.current.addCustomTime(customTime.tz('America/New_York'));

    timelinePlusLosRef.current = new Timeline(plusLosRef.current, items, {
      ...options,
      moment: (date) => moment(date).tz('America/Los_Angeles'),
    });
    timelinePlusLosRef.current.addCustomTime(
      customTime.tz('America/Los_Angeles')
    );

    timelinePlusBeijingRef.current = new Timeline(
      plusBeijingRef.current,
      items,
      {
        ...options,
        moment: (date) => moment(date).tz('Asia/Shanghai'),
      }
    );
    timelinePlusBeijingRef.current.addCustomTime(
      customTime.tz('Asia/Shanghai')
    );

    synchronizeTimelines(timelinePlusNyRef.current, [
      timelinePlusLosRef.current,
      timelinePlusBeijingRef.current,
    ]);
    synchronizeTimelines(timelinePlusLosRef.current, [
      timelinePlusNyRef.current,
      timelinePlusBeijingRef.current,
    ]);
    synchronizeTimelines(timelinePlusBeijingRef.current, [
      timelinePlusNyRef.current,
      timelinePlusLosRef.current,
    ]);
  }, []);

  return (
    <div className="w-full p-5">
      <h1 className="text-3xl font-bold mb-5">Time zone</h1>
      <p className="mb-5">
        The following demo shows how to display items in local time (default),
        in UTC, or for a specific time zone offset. By configuring your own
        <code> moment</code> constructor, you can display items in the time zone
        that you want. All timelines have the same start and end date.
      </p>

      <h2 className="text-2xl font-bold mb-2">Los Angeles Time</h2>
      <div
        id="plus_los"
        ref={plusLosRef}
        className="border border-gray-300 rounded-lg p-4 shadow-lg mb-5 w-full"
      ></div>

      <h2 className="text-2xl font-bold mb-2">Beijing Time</h2>
      <div
        id="plus_beijing"
        ref={plusBeijingRef}
        className="border border-gray-300 rounded-lg p-4 shadow-lg mb-5 w-full"
      ></div>
      
      <h2 className="text-2xl font-bold mb-2">New York Time</h2>
      <div
        id="plus_ny"
        ref={plusNyRef}
        className="border border-gray-300 rounded-lg p-4 shadow-lg mb-5 w-full"
      ></div>
    </div>
  );
};

export default TimeZoneAlign;
