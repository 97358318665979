import React, { useEffect } from 'react';
import SortCriteriaTable from '../common/SortCriteriaTable';
import ScrollableLayout from '../components/ScrollableLayout';
import './styles.css'; // 导入 CSS 文件
import { useEnglishArticleTextProcessor } from './EnglishArticleTextProcessorLogic';

function EnglishArticleTextProcessor() {
  const {
    text,
    setText,
    sortCriteriaTable,
    sortedWordsText,
    handleProcessText,
    handleAddSortCriterion,
    handleRemoveSortCriterion,
    handleMoveUp,
    handleMoveDown,
    handleColumnNameChange,
    handleSortOrderChange,
    getSortedData,
    filteredWords,
    handleLearnedToggle,
    handleSaveLearnedWords,
    handleImportLearnedWords,
    learnedWordsPercentage,
    wordFrequency
  } = useEnglishArticleTextProcessor();

  const sortedWordCounts = getSortedData() || [];

  const tableRef = React.useRef(null);

  const handleKeyDown = (event) => {
    console.log("handleKeyDown:");     
    console.log(event.key);

    const activeElement = document.activeElement;
    if (activeElement.tagName === 'INPUT' && activeElement.type === 'checkbox') {
      const row = activeElement.closest('tr');
      
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (row.previousElementSibling) {
            row.previousElementSibling.querySelector('input[type="checkbox"]').focus();
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (row.nextElementSibling) {
            row.nextElementSibling.querySelector('input[type="checkbox"]').focus();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          event.preventDefault();
          activeElement.checked = !activeElement.checked;
          handleLearnedToggle(row.firstElementChild.textContent);
          break;
      }
    }
  };

  useEffect(() => {
    // 创建一个MutationObserver实例，用于观察文档中的变化
    const observer = new MutationObserver((mutations) => {
      // 获取当前的表格元素
      const table = tableRef.current;
      if (table) {
        // 为表格元素添加键盘按下事件监听器
        table.addEventListener('keydown', handleKeyDown);
        console.log("事件监听器已添加");
        // 停止观察器的观察
        observer.disconnect();
      }
    });

    // 配置观察器观察文档body的子元素列表和子树的��化
    observer.observe(document.body, { childList: true, subtree: true });

    // 返回一个函数，用于在组件卸载时清理资源
    return () => {
      // 停止观察器的观察
      observer.disconnect();
      // 获取当前的表格元素
      const table = tableRef.current;
      if (table) {
        // 移除表格元素的键盘按下事件监听器
        table.removeEventListener('keydown', handleKeyDown);
        console.log("事件监听器已移除");
      }
    };
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">文本处理器</h2>
      <div className="form-control">
        <textarea
          className="textarea textarea-bordered h-24"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="在此输入您的文本"
        />
      </div>
      <button className="btn btn-primary mt-4" onClick={handleProcessText}>
        处理文本
      </button>

      <h3 className="text-xl font-semibold mt-6 mb-2">排序后的单词文本：</h3>
      <textarea
        className="textarea textarea-bordered w-full h-24"
        value={sortedWordsText}
        readOnly
      />

      <div className="my-8">
        <SortCriteriaTable
          sortCriteria={sortCriteriaTable}
          onAddCriterion={handleAddSortCriterion}
          onRemoveCriterion={handleRemoveSortCriterion}
          onMoveUp={handleMoveUp}
          onMoveDown={handleMoveDown}
          onColumnNameChange={handleColumnNameChange}
          onSortOrderChange={handleSortOrderChange}
        />
      </div>

      <div className="mt-4 space-y-2">
            <button 
              className="btn btn-secondary" 
              onClick={handleSaveLearnedWords}
            >
              保存已学会的单词到文件
            </button>
            <div>
              <input
                type="file"
                id="importLearnedWords"
                className="hidden"
                onChange={handleImportLearnedWords}
                accept=".txt"
              />
              <label 
                htmlFor="importLearnedWords" 
                className="btn btn-secondary cursor-pointer"
              >
                导入已学会的单词
              </label>
            </div>
          </div>

      <div className="sticky top-0 bg-base-100 pt-4 pb-2 z-50">
        <h3 className="text-xl font-semibold mb-2">已学会单词进度：</h3>
        <progress 
          className="progress progress-primary w-full" 
          value={learnedWordsPercentage} 
          max="100"
        ></progress>
        <p className="text-sm mt-1">
          {learnedWordsPercentage.toFixed(1)}% 的单词已学会
        </p>
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">单词计数：</h3>
      {sortedWordCounts.length > 0 ? (
        <ScrollableLayout maxHeight="90rem">
          <table className="table table-zebra w-full" ref={tableRef}>
            <thead>
              <tr>
                <th>单词</th>
                <th>计数</th>
                <th>长度</th>
                <th>词频</th>
                <th>排名</th>
                <th>是否已学会</th>
              </tr>
            </thead>
            <tbody>
              {sortedWordCounts.map(({ word, count, length, isLearned }) => (
                <tr key={word}>
                  <td>{word}</td>
                  <td>{count}</td>
                  <td>{length}</td>
                  <td>{wordFrequency.getFrequency(word).freq || 'N/A'}</td>
                  <td>{wordFrequency.getFrequency(word).rank || 'N/A'}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={isLearned}
                      onChange={() => handleLearnedToggle(word)}
                      className="checkbox"
                      tabIndex="0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollableLayout>
      ) : (
        <p className="text-gray-600">尚未处理任何单词。</p>
      )}

      <h3 className="text-xl font-semibold mt-6 mb-2">
        过滤的单词（用于验证）：
      </h3>
      <div className="overflow-x-auto">
        <table className="table table-zebra w-full">
          <thead>
            <tr>
              <th>过滤的单词</th>
            </tr>
          </thead>
          <tbody>
            {filteredWords.map((word, index) => (
              <tr key={index}>
                <td>{word}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EnglishArticleTextProcessor;
