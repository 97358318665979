// downloadTts.js
import axios from 'axios';

const downloadSynthesis = async (
  speechSDK,
  selectedConfig,
  format,
  speed,
  text,
  isSsml,
  setEvents,
  setResults,
  speechSynthesisVoiceName,
  setAudioPlayerUrl // 新增参数
) => {
  if (!selectedConfig) {
    alert('Please select a configuration');
    return;
  }

  const { subscriptionKey, region } = JSON.parse(
    localStorage.getItem(selectedConfig)
  );
  const headers = {
    headers: {
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const tokenResponse = await axios.post(
      `https://${region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
      null,
      headers
    );
    const token = tokenResponse.data;

    const speechConfig = speechSDK.SpeechConfig.fromAuthorizationToken(
      token,
      region
    );
    // speechConfig.speechSynthesisOutputFormat =
      // speechSDK.SpeechSynthesisOutputFormat[format];

    speechConfig.speechSynthesisOutputFormat = "Audio16Khz32KBitRateMonoMp3";
    speechConfig.speechSynthesisSpeakingRate = speed;
    speechConfig.speechSynthesisVoiceName = speechSynthesisVoiceName;

    const newSynthesizer = new speechSDK.SpeechSynthesizer(speechConfig, null);
    newSynthesizer.synthesisCompleted = (s, e) => {
      setEvents(
        (prevEvents) =>
          `${prevEvents} \r\n(synthesized)  Reason: ${
            speechSDK.ResultReason[e.result.reason]
          } Audio length: ${e.result.audioData.byteLength}\n`
      );

      if (
        e.result.reason === speechSDK.ResultReason.SynthesizingAudioCompleted
      ) {
        const url = window.URL.createObjectURL(new Blob([e.result.audioData]));
        console.log('synthesisCompleted url: ', url);
        // 使用 setTimeout 确保在主线程中更新状态
        setTimeout(() => {
          setAudioPlayerUrl(url);
        }, 0);
      } else {
        setEvents(
          (prevEvents) =>
            `${prevEvents} \r\n Synthesis failed. Error detail:  ${e.result.errorDetails}`
        );
      }
      newSynthesizer.close();
    };

    newSynthesizer.synthesizing = function (s, e) {
      console.log('合成中: {');
      console.log(e);
      console.log(
        `synthesizing e.result.audioData.byteLength: ${e.result.audioData.byteLength}`
      );
      console.log('合成中: }');
    };

    newSynthesizer.wordBoundary = function (s, e) {
      console.log(`wordBoundary: ${e}`);
      console.log(e);
      console.log(`wordBoundary: ${e.text}`);
      console.log(`wordBoundary: ${e.audioOffset/10000}`);
      
      // 将 wordBoundary 信息添加到 events 中
      setEvents((prevEvents) => 
        `${prevEvents}\r\nWordBoundary: 文本 "${e.text}", 时间戳 ${e.audioOffset/10000}秒`
      );
    };

    newSynthesizer.SynthesisCanceled = (s, e) => {
      const cancellationDetails = speechSDK.CancellationDetails.fromResult(
        e.result
      );
      let str = `(cancel) Reason: ${
        speechSDK.CancellationReason[cancellationDetails.reason]
      }`;
      if (cancellationDetails.reason === speechSDK.CancellationReason.Error) {
        str += `: ${e.result.errorDetails}`;
      }

      setEvents(
        (prevEvents) => `${prevEvents} \r\n SynthesisCanceled:  ${str}`
      );
      newSynthesizer.close();
    };

    if (isSsml) {
      newSynthesizer.speakSsmlAsync(text);
    } else {
      const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xml:lang='en-US'><voice name='Microsoft Server Speech Text to Speech Voice (af-ZA, AdriNeural)'><prosody rate='${speed}'>${text}</prosody></voice></speak>`;
      newSynthesizer.speakSsmlAsync(ssml);
    }
  } catch (err) {
    setResults(`There was an error authorizing your speech key: ${err}`);
  }
};

const getExtensionFromFormat = (format) => {
  format = format.toLowerCase();
  if (format.includes('mp3')) {
    return 'mp3';
  } else if (format.includes('ogg')) {
    return 'ogg';
  } else if (format.includes('webm')) {
    return 'webm';
  } else if (format.includes('riff')) {
    return 'wav';
  } else {
    return 'pcm';
  }
};

export { downloadSynthesis };
