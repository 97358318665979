import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Network } from 'vis-network';


const SampleCsvData = `
from:name;from:desc;to:name;to:desc
node1;description1;node2;description2
node2;description2;node38;description38
node1;description1;node3;description3
node2;description2;node3;description3
node3;description3;node56;description56
node3;description3;node81;description81
node2;description2;node4;description4
node1;description1;node4;description4
node4;description4;node78;description78
node1;description1;node5;description5
node4;description4;node5;description5
`;


const DependencyVisjsGraph = () => {
  const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
  const [inputJson, setInputJson] = useState('');
  const [inputCsv, setInputCsv] = useState(SampleCsvData); // Set SampleCsvData as the initial value

  // Function to convert CSV to JSON
  const convertCsvToJson = (csv) => {
    const lines = csv.trim().split('\n');
    const graph = lines.map((line) => {
      const [fromName, fromDesc, toName, toDesc] = line.split(';');
      return {
        from: {
          name: fromName.trim(),
          desc: fromDesc.trim(),
        },
        to: {
          name: toName.trim(),
          desc: toDesc.trim(),
        },
      };
    });
    return { graph };
  };

  useEffect(() => {
    // Function to convert JSON to graphData
    const convertJsonToGraphData = (json) => {
      if (!json || !json.graph) {
        console.error('Invalid or missing JSON data:', json);
        return { nodes: [], edges: [] };
      }

      const nodes = [];
      const edges = [];
      
      const savedPositions = JSON.parse(localStorage.getItem('nodePositions'));

      json.graph.forEach((item) => {
        const fromNode = { id: item.from.name, label: item.from.name };
        const toNode = { id: item.to.name, label: item.to.name };

        // Apply saved positions if available
        if (savedPositions && savedPositions[fromNode.id]) {
          fromNode.x = savedPositions[fromNode.id].x;
          fromNode.y = savedPositions[fromNode.id].y;
        }
        if (savedPositions && savedPositions[toNode.id]) {
          toNode.x = savedPositions[toNode.id].x;
          toNode.y = savedPositions[toNode.id].y;
        }

        // Avoid adding duplicate nodes
        if (!nodes.find((node) => node.id === fromNode.id))
          nodes.push(fromNode);
        if (!nodes.find((node) => node.id === toNode.id)) nodes.push(toNode);

        edges.push({ from: fromNode.id, to: toNode.id });
      });

      return { nodes, edges };
    };

    // Update the state with new graph data
    try {
      const parsedJson = inputJson ? JSON.parse(inputJson) : convertCsvToJson(inputCsv);
      const updatedGraphData = convertJsonToGraphData(parsedJson);
      setGraphData(updatedGraphData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }, [inputJson, inputCsv]);

  useEffect(() => {
    if (graphData.nodes.length && graphData.edges.length) {
      const container = document.getElementById('mynetwork');
      const options = {
        nodes: {
          shape: 'box',
          color: {
            background: '#97C2FC',
            border: '#2B7CE9',
          },
          margin: 10,
          font: {
            color: '#343434',
          },
          borderWidth: 2,
          cornerRadius: 10, // Rounded corners
        },
        layout: {
          hierarchical: {
            direction: 'UD', // From top to bottom
            sortMethod: 'directed', // Sort based on directed edges
          },
        },
        physics: false,
      };

      const network = new Network(container, graphData, options);

      network.on('dragEnd', function () {
        const positions = network.getPositions();
        localStorage.setItem('nodePositions', JSON.stringify(positions));
      });

      network.once('stabilized', () => {
        const positions = network.getPositions();
        const echartsData = graphData.nodes.map((node) => ({
          name: node.label,
          x: positions[node.id].x,
          y: positions[node.id].y,
        }));

        const echartsLinks = graphData.edges.map((edge) => ({
          source: graphData.nodes.find((node) => node.id === edge.from).label,
          target: graphData.nodes.find((node) => node.id === edge.to).label,
        }));

        const myChart = echarts.init(document.getElementById('main'));
        const option = {
          series: [
            {
              type: 'graph',
              layout: 'none',
              data: echartsData,
              links: echartsLinks,
              roam: true,
              label: {
                show: true,
              },
            },
          ],
        };
        myChart.setOption(option);
      });
    }
  }, [graphData]);

  const handleInputChange = (event) => {
    setInputJson(event.target.value);
  };


  const handleCsvInputChange = (event) => {
    setInputCsv(event.target.value);
  };

  return (
    <div>
      <h1>CSV</h1>
      <textarea
        value={inputCsv}
        onChange={handleCsvInputChange}
        placeholder="Paste CSV data here"
        style={{ width: '100%', height: '100px', marginBottom: '20px' }}
      />
      <h1>JSON</h1>
      <textarea
        value={inputJson}
        onChange={handleInputChange}
        placeholder="Paste JSON data here"
        style={{ width: '100%', height: '100px', marginBottom: '20px' }}
      />
      <div
        id="mynetwork"
        style={{
          width: '100%',
          height: '1000px',
          border: '1px solid lightgray',
        }}
      ></div>
      <div id="main" style={{ width: '100%', height: '400px' }}></div>
    </div>
  );
};

export default DependencyVisjsGraph;
