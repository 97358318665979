// TTSPageFunSynthesis.js
// TTSPageFunSynthesis.js
// 定义 AudioPlayer 类
class AudioPlayer {
  constructor(
    speechSDK,
    setHighlightText,
    setVarEvents,
    setStartSynthesisAsyncButtonEnable,
    setDownloadButtonEnable,
    setPausedEnable,
    setResumeButtonEnable
  ) {
    this.player = new speechSDK.SpeakerAudioDestination();
    this.wordBoundaries = [];
    this.setHighlightText = setHighlightText;
    this.setVarEvents = setVarEvents;
    this.setStartSynthesisAsyncButtonEnable =
      setStartSynthesisAsyncButtonEnable;
    this.setDownloadButtonEnable = setDownloadButtonEnable;
    this.setPausedEnable = setPausedEnable;
    this.setResumeButtonEnable = setResumeButtonEnable;
    this.updateInterval = null;
    this.eventStr = '';
    this.audioChunks = [];
    this.audioBlob = null;

    this.initializePlayer();
  }

  initializePlayer() {
    this.player.onAudioStart = this.onAudioStart.bind(this);
    this.player.onAudioEnd = this.onAudioEnd.bind(this);
    this.player.onAudioUpdate = this.onAudioUpdate.bind(this);
  }

  onAudioStart(_) {
    console.log('播放开始');
    this.setHighlightText('');
    this.startUpdateInterval();
  }

  onAudioEnd(_) {
    console.log('播放结束');
    this.eventStr = `${this.eventStr} "1ddd  ddd"`;
    this.setVarEvents(`${this.eventStr} "\r\n 播放结束"`);
    this.setStartSynthesisAsyncButtonEnable(false);
    this.setDownloadButtonEnable(false);
    this.setPausedEnable(true);
    this.setResumeButtonEnable(true);
    this.setHighlightText('');
    this.wordBoundaries = [];
    this.clearUpdateInterval();
  }

  onAudioUpdate(audioPosition) {
    console.log(`onAudioUpdate audioPosition: ${audioPosition}`);
    this.updateHighlightText(audioPosition);
  }

  startUpdateInterval() {
    this.updateInterval = setInterval(() => {
      if (this.player && this.player.currentTime !== undefined) {
        this.onAudioUpdate(this.player.currentTime * 1000);
      }
    }, 100);
  }

  clearUpdateInterval() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  updateHighlightText(audioPosition) {
    let currentWord = '';
    let highlightedText = '';

    for (let i = 0; i < this.wordBoundaries.length; i++) {
      const wordStart = this.wordBoundaries[i].offset / 10000;
      const wordEnd =
        i < this.wordBoundaries.length - 1
          ? this.wordBoundaries[i + 1].offset / 10000
          : Infinity;

      if (audioPosition >= wordStart && audioPosition < wordEnd) {
        currentWord = this.wordBoundaries[i].text;
        highlightedText += `<span class="current-word">${currentWord}</span> `;
      } else if (audioPosition < wordStart) {
        highlightedText += this.wordBoundaries[i].text + ' ';
      } else {
        highlightedText += `<span class="highlighted">${this.wordBoundaries[i].text}</span> `;
      }
    }

    console.log(`更新高亮文本: ${highlightedText}`);
    console.log(`当前单词: ${currentWord}`);

    setTimeout(() => {
      this.setHighlightText(currentWord);
    }, 0);
  }

  addWordBoundary(text, offset) {
    this.wordBoundaries.push({ text, offset });
  }

  getAudioConfig(speechSDK) {
    return speechSDK.AudioConfig.fromSpeakerOutput(this.player);
    // return speechSDK.AudioConfig.fromAudioFileOutput("output.wav");
  }

  addAudioChunk(audioData) {
    this.audioChunks.push(audioData);
    // 打印 总数据大小
    console.log(`audioChunks 总数据大小 : ${this.audioChunks.length}`);
    for (let i = 0; i < this.audioChunks.length; i++) {
      console.log(`audioChunks[${i}] 大小 : ${this.audioChunks[i].byteLength}`);
    }
  }

  createAudioBlob() {
    for (let i = 0; i < this.audioChunks.length; i++) {
      console.log(
        `createAudioBlob [${i}] 大小 : ${this.audioChunks[i].byteLength}`
      );
      this.audioBlob = new Blob([this.audioChunks[i]], { type: 'audio/mp3' });
      this.createAudioPlayer();
    }

    // 计算所有 ArrayBuffer 的总长度
    const totalLength = this.audioChunks.reduce(
      (acc, val) => acc + val.byteLength,
      0
    );

    // 创建一个新的 ArrayBuffer 来存储所有数据
    const combinedBuffer = new ArrayBuffer(totalLength);

    // 使用一个 Uint8Array 来操作 ArrayBuffer
    let currentOffset = 0;
    const combinedArray = new Uint8Array(combinedBuffer);

    // 将每个 ArrayBuffer 的内容复制到新的 ArrayBuffer
    this.audioChunks.forEach((buffer) => {
      combinedArray.set(new Uint8Array(buffer), currentOffset);
      currentOffset += buffer.byteLength;
    });

    // 现在 combinedBuffer 包含了所有 ArrayBuffer 的合并数据
    console.log(combinedBuffer);
    this.audioBlob = new Blob([combinedBuffer], { type: 'audio/mp3' });
    this.createAudioPlayer();
  }

  createAudioPlayer() {
    const audioUrl = URL.createObjectURL(this.audioBlob);
    const audioPlayer = document.createElement('audio');
    audioPlayer.controls = true;
    audioPlayer.src = audioUrl;
    document.body.appendChild(audioPlayer);
  }
}

export const startSynthesis = async (
  speechSDK,
  selectedConfigKey,
  text,
  isSsml,
  setEvents,
  setStartSynthesisAsyncButtonEnable,
  setDownloadButtonEnable,
  setPausedEnable,
  setResumeButtonEnable,
  setVarEvents,
  speechSynthesisVoiceName,
  setHighlightText
) => {
  let chunkCount = 0;
  let eventStr = '';
  if (!selectedConfigKey) {
    alert('请选择一个配置');
    return;
  }

  const { subscriptionKey, region } = JSON.parse(
    localStorage.getItem(selectedConfigKey)
  );

  var speechConfig;

  console.log(`authorizationToken 2`);

  if (
    subscriptionKey.value === '' ||
    subscriptionKey.value === 'subscription'
  ) {
    alert('请输入您的Microsoft认知服务语音订阅密钥！');
    return;
  }
  console.log(`authorizationToken 3`);
  console.log(`subscriptionKey ${subscriptionKey}`);
  console.log(`region ${region}`);

  speechConfig = speechSDK.SpeechConfig.fromSubscription(
    subscriptionKey,
    region
  );

  speechConfig.speechSynthesisVoiceName = speechSynthesisVoiceName;
  speechConfig.speechSynthesisOutputFormat = 'Audio16Khz32KBitRateMonoMp3';
  speechConfig.speechSynthesisSpeakingRate = 0.3;

  const audioPlayer = new AudioPlayer(
    speechSDK,
    setHighlightText,
    setVarEvents,
    setStartSynthesisAsyncButtonEnable,
    setDownloadButtonEnable,
    setPausedEnable,
    setResumeButtonEnable
  );

  var audioConfig = audioPlayer.getAudioConfig(speechSDK);
  var synthesizer = new speechSDK.SpeechSynthesizer(speechConfig, audioConfig);

  // 清空事件日志
  setEvents('');

  synthesizer.synthesizing = function (s, e) {
    console.log('合成中: {');
    console.log(
      `synthesizing e.result.audioData.byteLength: ${e.result.audioData.byteLength}`
    );
    chunkCount = chunkCount + 1;
    console.log(`chunkCount : ${chunkCount}`);

    audioPlayer.addAudioChunk(e.result.audioData);
    setEvents(
      (prevEvents) =>
        `${prevEvents}\r\n(合成中) 原因: ${
          speechSDK.ResultReason[e.result.reason]
        } 音频块长度: ${e.result.audioData.byteLength}`
    );
    console.log('合成中: }');
  };

  synthesizer.synthesisStarted = function (s, e) {
    console.log('合成开始: {');
    setEvents((prevEvents) => `${prevEvents}\r\n合成开始`);
    setPausedEnable(false);
  };

  synthesizer.synthesisCompleted = function (s, e) {
    console.log('合成完成: {');
    console.log(e);
    setEvents((prevEvents) => `${prevEvents}\r\n合成完成`);
    console.log(`audioChunks 长度 : ${audioPlayer.audioChunks.length}`);

    audioPlayer.createAudioBlob();
  };

  synthesizer.SynthesisCanceled = function (s, e) {
    const cancellationDetails = speechSDK.CancellationDetails.fromResult(
      e.result
    );
    let str =
      '(取消) 原因: ' +
      speechSDK.CancellationReason[cancellationDetails.reason];
    if (cancellationDetails.reason === speechSDK.CancellationReason.Error) {
      str += ': ' + e.result.errorDetails;
    }

    setEvents((prevEvents) => `${prevEvents}\r\n  合成完成 ${str}`);
    setStartSynthesisAsyncButtonEnable(false);
    setDownloadButtonEnable(false);
    setPausedEnable(true);
    setResumeButtonEnable(true);
  };

  synthesizer.wordBoundary = function (s, e) {
    window.console.log(e);
    audioPlayer.addWordBoundary(e.text, e.audioOffset);
    setEvents(
      (prevEvents) =>
        `${prevEvents}  \r\n(单词边界), 文本:  ${e.text}  , 音频偏移:  ${
          e.audioOffset / 10000
        }  毫秒.`
    );
  };

  synthesizer.visemeReceived = function (s, e) {
    window.console.log(e);
    // 可以在这里添加额外的可视化效果，如口型动画
  };

  synthesizer.bookmarkReached = function (s, e) {
    window.console.log(e);
    setEvents(
      (prevEvents) =>
        `${prevEvents} "\r\n (书签到达), 音频偏移: ${
          e.audioOffset / 10000
        } 毫秒. 书签文本: ${e.text} "`
    );
  };

  const complete_cb = function (result) {
    console.log('complete_cb ');

    audioPlayer.clearUpdateInterval();
    if (result.reason === speechSDK.ResultReason.SynthesizingAudioCompleted) {
      console.log('音频合成完成');
    } else if (result.reason === speechSDK.ResultReason.Canceled) {
      console.log('音频合成被取消');
      const cancellationDetails =
        speechSDK.CancellationDetails.fromResult(result);
      setEvents(
        (prevEvents) =>
          `${prevEvents}\r\n音频合成被取消: ${
            speechSDK.CancellationReason[cancellationDetails.reason]
          }`
      );
      if (cancellationDetails.reason === speechSDK.CancellationReason.Error) {
        setEvents(
          (prevEvents) =>
            `${prevEvents}\r\n错误详情: ${cancellationDetails.errorDetails}`
        );
      }
    }
    window.console.log(result);
    synthesizer.close();
    synthesizer = undefined;
  };

  const err_cb = function (err) {
    setStartSynthesisAsyncButtonEnable(false);
    setDownloadButtonEnable(false);
    window.console.log(err);
    setEvents(
      (prevEvents) =>
        `${prevEvents}\r\n合成失败: ${err.message || JSON.stringify(err)}`
    );
    synthesizer.close();
    synthesizer = undefined;
  };

  setStartSynthesisAsyncButtonEnable(true);
  setDownloadButtonEnable(true);

  console.log(`isSsml: ${isSsml}`);
  if (isSsml) {
    synthesizer.speakSsmlAsync(text, complete_cb, err_cb);
  } else {
    synthesizer.speakTextAsync(text, complete_cb, err_cb);
  }
};
